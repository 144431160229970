import React, { useState } from "react";
import { Button } from "src/components/Button";
import DownloadableReceipt from "src/components/DownloadableReciept";
import { TableLoader } from "src/components/LoaderContainer";
import ReceiptCard from "src/components/ReceiptCard";
import ViewWalletComp from "src/components/ViewWalletComponent";

import useHandleRowClick from "src/hooks/useHandleRowClick";

export default function Transaction({
  transactions,
  transactionResult,
  headCells,
  handleChangePage,
}: {
  transactions: any;
  transactionResult: any;
  handleChangePage?: (arg: any, arg2: number) => void;
  headCells: any;
}) {
  const [transactionData, setTransactionData] = useState<any>({});
  const { showModal, setShowModal, handleRowClick } = useHandleRowClick(fn);

  // HANDLE CHANGE FOR PAGINATION
  function fn(data: { [index: string]: string | number }) {
    const {
      meta,
      stationBranch,

      updatedAt,
      createdAt,
      referenceId,
      transactionDump,
      purpose,
      stationHQ,
      pumpAttendant,
      id,
      type,

      ...rest
    } = data;

    setTransactionData({ ...rest, ...(meta as any) });
    setShowModal((prev) => !prev);
  }
  const props = {
    rows: transactions || [],
    headCells,
    handleRowClick,
    accountInformation: {
      balance: 0,
      amountIn: 0,
      amountOut: 0,
    },
    handleChangePage: handleChangePage,
    paginationData: {
      totalPage: transactionResult?.data?.totalPages,
      limit: transactionResult?.data?.limit,
      page: transactionResult?.data?.page,
    },
  };

  return (
    <section>
      <article>
        <div className="">
          <div className="h-full  w-full bg-white shadow-lg rounded-t-lg">
            <TableLoader
              data={transactionResult}
              tableData={transactions || []}
            >
              <ViewWalletComp {...props} />
            </TableLoader>
          </div>
        </div>
        {showModal ? (
          <ReceiptCard
            data={transactionData.previewData || transactionData}
            setShowModal={setShowModal}
          />
        ) : null}
      </article>
    </section>
  );
}
