import React, { useMemo, useState } from "react";
import { Button } from "src/components/Button";
import useCustomLocation from "src/hooks/useCustomLocation";
import { LoaderContainer, TableLoader } from "src/components/LoaderContainer";

import {
	CurrencyFormatter,
	ErrorNotification,
	SuccessNotification,
} from "src/helpers/helperFunction";

import DepotProducts from "./components/DepotProducts";

import { useFetchSingleInvoiceQuery } from "src/api/manageBranchAPISlice";

import { useInvoicePaymentMutation } from "src/hq-admin/hq-api/manageHqApiSlice";
import ViewWalletComp from "src/components/ViewWalletComponent";

// TAB DATA FOR TABLE TAB
// const tabData: { id: number; value: string; label: string }[] = [
// 	{ id: 1, value: "one", label: "Depot Products" },
// ];

const headCells: any[] = [
	{
		id: "name",
		minWidth: 170,
		label: "Product name",
	},

	{
		id: "amount",
		minWidth: 100,
		label: "Amount",
	},
	{
		id: "availableVolume",
		minWidth: 100,
		label: "Available Volume",
	},
	{
		id: "requestedVolume",
		minWidth: 100,
		label: "Requested Volume",
	},
];

export default function SingleInvoice() {
	// const { listOfRegisteredDepot } = useRegisteredDepot();
	const { slicedPath, routePath } = useCustomLocation();

	const getSingleDepotQuery = useFetchSingleInvoiceQuery({
		id: slicedPath[2],
	});

	const [invoicePaymentMutation, invoicePaymentMutationResult] =
		useInvoicePaymentMutation();

	const singleInvoiceDetails = useMemo(
		() => getSingleDepotQuery?.data,
		[getSingleDepotQuery]
	);

	async function handleInvoicePayment() {
		try {
			const response = await invoicePaymentMutation({
				invoiceId: singleInvoiceDetails.data?.id,
			}).unwrap();
			SuccessNotification(response?.data?.message);
		} catch (error: any) {
			ErrorNotification(error?.data?.message || error?.message);
		}
	}

	const props = {
		rows: singleInvoiceDetails?.data?.order?.details,
		headCells,
		handleRowClick: () => {},
		handleChangePage: () => {},
		paginationData: {
			totalPage: 0,
			limit: 0,
			page: 1,
		},
	};

	return (
		<section>
			<LoaderContainer data={getSingleDepotQuery}>
				<article>
					<div className="flex flex-col items-center gap-y-3">
						<div>
							<img
								src={singleInvoiceDetails?.data?.depot?.logo?.url}
								className="h-[97px] w-[97px]  rounded-full object-cover"
								alt=""
							/>
						</div>

						<div className="text-2xl ">
							<h6 className="font-bold text-black capitalize mt-3">
								{singleInvoiceDetails?.data?.depot?.name}
							</h6>
						</div>
						<div className="text-lg ">
							<h2 className="font-bold text-black capitalize mt-3">Note</h2>
							<p className="text-sm leading-loose">
								{singleInvoiceDetails?.data?.invoiceNote}
							</p>
						</div>
						<div className=" grid grid-cols-3">
							<div className="text-lg col-span-3 ">
								<h2 className="text-base font-bold text-black capitalize mt-3">
									Invoice number
								</h2>
								<h3 className=" text-lg leading-loose font-normal">
									{singleInvoiceDetails?.data?.invoiceNumber}
								</h3>
							</div>
							<div className="text-lg ">
								<h2 className="text-base font-bold text-black capitalize mt-3">
									Total amount
								</h2>
								<h3 className="text-lg leading-loose font-normal">
									{CurrencyFormatter(singleInvoiceDetails?.data?.totalAmount)}
								</h3>
							</div>
							<div className="text-lg ">
								<h2 className="text-base font-bold text-black capitalize mt-3">
									Tax deducted
								</h2>
								<h3 className="text-lg leading-loose font-normal">
									{CurrencyFormatter(singleInvoiceDetails?.data?.tax)}
								</h3>
							</div>
							<div className="text-lg ">
								<h2 className="text-base font-bold text-black capitalize mt-3">
									Discount
								</h2>
								<h3 className="text-lg leading-loose font-normal">
									{CurrencyFormatter(singleInvoiceDetails?.data?.discount)}
								</h3>
							</div>
						</div>
						<div className=" ">
							{singleInvoiceDetails?.data?.order?.status === "pending" &&
							singleInvoiceDetails?.data?.paymentStatus !== "paid" ? (
								<Button
									className=" bg-green-600 text-white px-5 py-3 text-sm font-semibold  rounded-3xl"
									text="Make Payment"
									type="button"
									onClick={handleInvoicePayment}
									showModal={invoicePaymentMutationResult?.isLoading}
								/>
							) : singleInvoiceDetails?.data?.paymentStatus === "paid" ? (
								<h3>Payment Made</h3>
							) : (
								<h3>Order Cancelled</h3>
							)}
						</div>
						<div className="mx-auto"></div>
					</div>
					<div className="h-fit w-full bg-white mt-6 shadow-lg rounded-t-lg overflow-x-auto">
						<TableLoader
							data={getSingleDepotQuery}
							tableData={singleInvoiceDetails?.data?.order?.details || []}>
							<ViewWalletComp {...props} />
						</TableLoader>
					</div>
				</article>
			</LoaderContainer>
		</section>
	);
}
