/* eslint-disable no-undef */
import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { apiSlice } from "src/api/apiSlice";
import { authAPISlice } from "src/api/authApiSlice";
import { manageBranAPISlice } from "src/api/manageBranchAPISlice";
import { manageHqAPISlice } from "src/api/manageHQApiSlice";
import { settingsAPISlice } from "src/api/setttingsApislice";
import { loginResponseType } from "src/helpers/alias";
import { KEYS } from "src/helpers/Constant";
import { encryptData } from "src/helpers/encryptData";
import { useAppDispatch } from "src/hooks/reduxhooks";
import { hqAuthAPISlice } from "src/hq-admin/hq-api/hqAuthSlice";
import { hQManageBranchApiSlice } from "src/hq-admin/hq-api/manageHqApiSlice";
import { RootState, store } from "src/store/store";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    systemHq: {
      role: null,
      firstName: "",
      lastName: "",
      avatar: { url: "" },
    },
    registeredDepots: [],
    token: { accessToken: null },
    products: [],
    branchList: [],
  } as unknown as any,

  reducers: {
    setCredentials: (state, action) => {
      state.token.accessToken = action.payload?.token;
      state.token.refreshToken = action.payload?.token;
      state.systemHq = action.payload?.user;
      // state.systemHq.role = action.payload?.systemHq.role;
    },

    logOut: (state) => {
      state.systemHq = null;
      state.token = { accessToken: null };
      localStorage.removeItem(KEYS.USER_INFO);
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentLoginUser = (state: RootState) =>
  state.authSlice.systemHq;
export const selectCurrentLoginToken = (state: RootState) =>
  state.authSlice.token;
export const selectRefreshToken = (state: RootState) =>
  state.authSlice.token.refreshToken;
export const depotProducts = (state: RootState) => state.authSlice.products;
export const registerdepots = (state: RootState) =>
  state.authSlice.registeredDepots;

export const branchList = (state: RootState) => state.authSlice.branchList;
