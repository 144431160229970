import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	useGetMyDepotListQuery,
	useRequestSummaryQuery,
} from "src/api/manageBranchAPISlice";
import { Button } from "src/components/Button";
import { LoaderContainer, TableLoader } from "src/components/LoaderContainer";
import { SearchInput } from "src/components/inputs";
import { HQ_APP_ROUTE } from "src/helpers/Constant";
import { useDebounce } from "src/hooks/useDebounce";
import SummaryCard from "./components/SummaryCard";
import SummaryIcon from "src/assets/img/SummaryIcon.svg";
import useHandleRowClick from "src/hooks/useHandleRowClick";
import useHandleSelectAllClick from "src/hooks/useHandleSelectAllClick";
import useHandleSingleSelect from "src/hooks/useHandleSingleSelect";
import useIsSelected from "src/hooks/useIsSelected";
import EnhancedTable from "src/components/Table";
import { format } from "date-fns";

const headCells: readonly any[] = [
	{
		id: "name",
		minWidth: 170,
		label: "Name",
	},
	{
		id: "phoneNumber",
		minWidth: 170,
		label: "Contact info",
	},
	{
		id: "email",
		minWidth: 170,
		label: "Emaill address",
	},
	{
		id: "address",
		minWidth: 170,
		label: "Address",
	},
	{
		id: "date",
		minWidth: 170,
		label: "Date",
	},
	{
		id: "status",
		minWidth: 170,
		label: "Status",
	},
];

export default function DepotList() {
	const [filteredValue, setFilteredValue] = useState<string>("");
	const { debouncedValue } = useDebounce(filteredValue, 700);
	const navigate = useNavigate();

	const [pagination, setPagination] = useState(1);

	const myDepotListResult = useGetMyDepotListQuery({
		// stationHQ: hqId,
		...(debouncedValue && { search: debouncedValue }),
		page: pagination,
		orderBy: "createdAt:desc",
	});

	const summarylist = useRequestSummaryQuery({});
	const summarylistData = useMemo(() => summarylist?.data, [summarylist]);

	function fn(data: { [index: string]: string | number }) {
		navigate(`/depot/${data?.id}`, { state: data.depot });
	}

	const handleChangePage = (e: any, newPage: number) => {
		setPagination(newPage);
	};
	const handledAPIResponse = useMemo(() => {
		const response = myDepotListResult?.data;

		const normalizedAPIResponse = response?.depots?.reduce(
			(acc: any[], cur: any) => [
				...acc,
				{
					date: format(new Date(cur.createdAt), "d/MM/yyyy"),
					id: cur?.depot?._id,
					name: (
						<div className="flex items-center gap-x- ">
							<img
								src={cur?.depot?.logo?.url}
								alt={cur?.depot?.name}
								className="h-6 w-6 rounded-full object-contin"
							/>
							{cur?.depot?.name}
						</div>
					),
					depot: cur?.depot?.name,
					phoneNumber: cur?.depot?.phoneNumber,
					email: cur?.depot?.email,
					lga: cur?.location?.lga,
					address: cur?.depot?.address,
					state: cur?.depot?.state,
					// status: cur?.depot?.status?.status,
					status: (
						<p
							className={`${
								cur?.depot?.status?.status?.toLowerCase() === "inactive"
									? "text-yellow-500"
									: cur?.depot?.status?.status?.toLowerCase() === "active"
									? "text-green-500"
									: "text-red-500"
							}`}>
							{cur?.depot?.status?.status}
						</p>
					),
				},
			],
			[]
		);
		return { normalizedAPIResponse, response };
	}, [myDepotListResult]);

	const { handleSelectAllClick, selected, setSelected } =
		useHandleSelectAllClick(handledAPIResponse);
	const { handleClick } = useHandleSingleSelect(selected, setSelected);
	const { handleRowClick } = useHandleRowClick(fn);
	const { isSelected } = useIsSelected(selected);

	let dataToChildren: { [index: string]: string | number | any } = {
		rows: handledAPIResponse?.normalizedAPIResponse || [],
		headCells,
		handleRowClick,
		isSelected,
		handleClick,
		handleSelectAllClick,
		selected,
		handleChangePage,
		paginationData: {
			totalPage: handledAPIResponse?.response?.data?.totalPages,
			limit: handledAPIResponse?.response?.data?.limit,
			page: handledAPIResponse?.response?.data?.page,
		},
	};

	return (
		<section>
			<div className="grid grid-cols-3 gap-x-3 w-full">
				<SummaryCard
					icon={SummaryIcon}
					name="Total request"
					amount={summarylistData?.requests}
					isDepot={true}
				/>
				<SummaryCard
					icon={SummaryIcon}
					name="Invoices"
					amount={summarylistData?.invoices}
					isDepot={true}
				/>
			</div>

			<LoaderContainer data={myDepotListResult}>
				<article className="">
					<div className="flex justify-between items-center mt-6 h-20">
						<div className="flex w-[50%] h-11  max-w-[562px] items-center gap-2 rounded-[15px] border-2 border-[#D0D5DD] bg-[#D9D9D9] px-[18px]">
							<SearchInput
								name="branch-search"
								placeholder="Search for name"
								value={filteredValue}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									const target = e.target;
									setFilteredValue(target.value);
								}}
							/>
						</div>
						<div className="w-fit flex items-center">
							<Button
								text="Orders"
								className="h-full font-bold text-white px-10 py-2 rounded-[38px] text-center w-full hover: bg-[#002E66] flex items-center justify-start "
								type="button"
								onClick={() =>
									navigate(HQ_APP_ROUTE.REQUEST_LIST, {
										state: "Order list",
									})
								}
							/>
						</div>
					</div>
					<div className="">
						<TableLoader
							data={myDepotListResult}
							tableData={handledAPIResponse?.normalizedAPIResponse || []}>
							<div className="h-full w-full">
								<div className="relative">
									<EnhancedTable {...dataToChildren} />
								</div>
							</div>
						</TableLoader>
					</div>
				</article>
			</LoaderContainer>
		</section>
	);
}
