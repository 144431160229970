import { format } from "date-fns";
import React from "react";
import {
	usePayDepotMutation,
	useVolumeReactionMutation,
} from "src/api/manageBranchAPISlice";
import { Button } from "src/components/Button";
import SingleListComp from "src/components/SingleListComp";
import {
	CurrencyFormatter,
	ErrorNotification,
	SuccessNotification,
} from "src/helpers/helperFunction";
import useCustomLocation from "src/hooks/useCustomLocation";

export default function OrderConfirmation({
	previewData,
	invoice,
	onClose,
}: {
	previewData: { [x: string]: string | any };
	invoice: { [x: string]: string | any };
	onClose: () => void;
}) {
	const { slicedPath } = useCustomLocation();
	const [volumeReactionMutation, volumeReactionResult] =
		useVolumeReactionMutation();
	const [payMutation, payMutationResult] = usePayDepotMutation();
	let accept = true;
	const handleAcceptVolume = async (acceptVolume: boolean) => {
		try {
			const result = await volumeReactionMutation({
				id: slicedPath[2],
				product: previewData?.product?.id,
				isAvailableVolumeAcceptedByHq: acceptVolume,
			}).unwrap();
			SuccessNotification("Request is " + result?.data?.status);
		} catch (error: any) {
			ErrorNotification(error?.data?.message);
		}
		onClose();
	};
	const handlePay = async () => {
		try {
			const result = await payMutation({
				invoiceId: invoice?.id,
			}).unwrap();

			SuccessNotification(result?.status);
			onClose();
		} catch (error: any) {
			onClose();
			ErrorNotification(error?.data?.message);
		}
	};

	return (
		<div className="flex flex-col items-center gap-y-6">
			<div className="text-2xl ">
				<h6 className="font-bold text-black mt-3">
					{previewData.product?.name}
				</h6>
			</div>
			<div className=" ">
				{invoice?.id ? (
					<Button
						className=" bg-primary text-white px-5 py-2 text-sm font-semibold  md:w-[150px] rounded-3xl"
						text="Pay"
						type="button"
						showModal={payMutationResult?.isLoading}
						onClick={handlePay}
					/>
				) : previewData?.isAvailableVolumeAcceptedByHq ? (
					<div>
						<h4 className="text-green-600"> Awaiting Response from Depot</h4>
					</div>
				) : (
					<div className="">
						<Button
							className=" bg-primary text-white px-5 py-2 text-sm font-semibold mr-4 md:w-[150px] rounded-3xl"
							text="Accept volume"
							type="button"
							onClick={() => handleAcceptVolume(accept)}
							showModal={
								volumeReactionResult?.isLoading &&
								volumeReactionResult?.originalArgs
									?.isAvailableVolumeAcceptedByHq
							}
						/>
						<Button
							className=" bg-red-800 text-white px-5 py-2 text-sm font-semibold  md:w-[150px] rounded-3xl"
							text="Reject volume"
							type="button"
							showModal={
								volumeReactionResult?.isLoading &&
								!volumeReactionResult?.originalArgs
									?.isAvailableVolumeAcceptedByHq
							}
							onClick={() => handleAcceptVolume(!accept)}
						/>
					</div>
				)}
			</div>
			<div>
				<div className="grid grid-cols-3 gap-y-10 justify-content-center py-2">
					<SingleListComp
						name="Available volume"
						value={previewData?.availableVolume}
					/>
					<SingleListComp
						name="Requested volume"
						value={previewData?.requestedVolume}
					/>

					<SingleListComp
						name="Quantity"
						value={`${previewData?.product?.quantity}${previewData?.product?.unitOfMeasurement}`}
					/>
					<SingleListComp
						name="Price"
						value={CurrencyFormatter(previewData?.product?.price)}
					/>
				</div>
			</div>
		</div>
	);
}
