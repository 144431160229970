import { Select, MenuItem, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "src/components/Button";
import { Error, FormInput, PasswordInput } from "src/components/inputs";
import { branchList } from "src/features/auth/authSlice";
import { Label } from "src/components/inputs";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import {
  SuccessNotification,
  ErrorNotification,
} from "src/helpers/helperFunction";
import {
  useBankListQuery,
  useGetAllBrachesQuery,
  useValidateAccountNumberForWithdrawalMutation,
  useVerifyTransactionPinMutation,
  useWithDrawFromStationMutation,
} from "src/hq-admin/hq-api/hqTransactionApiSlice";
import { toast } from "react-toastify";

const WithDrawalValidation = {
  destinationBankAccountNumber: Yup.string()
    .label("phone number")
    .length(10, "invalid")
    .required(),
  purpose: Yup.string().required(),
  destinationBankUUID: Yup.string().required(),
  amount: Yup.number().required(),
};

export const WithDrawalMoal = ({ closeModal }: any) => {
  const [showPinInput, setShowPinInput] = useState(false);
  const [pin, setPin] = useState("");
  const [withDrawMutation, widthDrawalResult] =
    useWithDrawFromStationMutation();
  const [
    validateAccountNumberForWithdrawal,
    { ...validateAccountNumberForWithdrawalDetails },
  ] = useValidateAccountNumberForWithdrawalMutation();

  const [accountNumberValidationResponse, setAccountNumberValidationResponse] =
    useState<any>();

  const Formik = useFormik<any>({
    initialValues: {
      amount: 0,
      destinationBankUUID: "",
      destinationBankAccountNumber: "",
      destinationAccountHolderNameAtBank: "",
      purpose: "",
      destinationBankAccountNumberValidation: false,
      idempotentKey: "",
    },
    onSubmit: async (values) => {
      if (!showPinInput) {
        setShowPinInput(true);
        return;
      }

      try {
        const pinResponse = await verifyTransactionPin({ pin }).unwrap();
        console.log(pinResponse);

        if (!pinResponse?.isPinValid) {
          return toast.error("Invalid PIN. Please try again.");
        }
        const response = await withDrawMutation({
          ...values,
          amount: Number(values.amount),
          destinationBankUUID: values.destinationBankUUID.uuid,
          idempotentKey: uuidv4(),
        }).unwrap();

        SuccessNotification("Successful");
      } catch (error: any) {
        ErrorNotification(error?.data?.message);
      }
      closeModal();
    },
  });

  const labelStyles =
    "block mb-[6px] text-black text-start font-normal text-[20px] text-gray-700 ";

  const res = useBankListQuery({});

  const hqBranches = useSelector(branchList);
  const { data: hqBranchesAPi } = useGetAllBrachesQuery({});
  const datalistRef = useRef<any>();
  const [verifyTransactionPin, { isLoading: isVerifyingPin }] =
    useVerifyTransactionPinMutation();

  const handledAPIResponse = useMemo(() => {
    const branches = hqBranchesAPi?.data || [];

    return branches?.data;
  }, [hqBranchesAPi]);

  const handleDatalistChange = (event: any) => {
    const joinedName = event?.target?.value?.toLowerCase();
    const bankUUID = res?.data?.find((item: any) =>
      item?.name?.toLowerCase().includes(joinedName)
    );

    Formik.setFieldValue("destinationBankUUID", bankUUID);
    handleValidateAccountNumberChange(
      Formik.values.destinationBankAccountNumber
    );
  };

  const handleValidateAccountNumberForWithdrawal = async (value: string) => {
    const { data: validationResponse }: any =
      await validateAccountNumberForWithdrawal({
        accountNumber: value,
        bankId: Formik.values.destinationBankUUID.uuid,
      });

    setAccountNumberValidationResponse(validationResponse);

    if (validationResponse.responseCode === 0) {
      Formik.setFieldValue(
        "destinationBankAccountName",
        validationResponse.destinationAccountHolderNameAtBank
      );
    }
    console.log(validationResponse);

    Formik.setFieldError(
      "destinationBankAccountNumber",
      "Invalid Account Number"
    );
    Formik.setFieldValue("destinationBankAccountNumberValidation", false);
  };

  const handleValidateAccountNumberChange = async (value: string) => {
    setAccountNumberValidationResponse(null);

    if (value.length === 10 && Formik.values.destinationBankUUID) {
      handleValidateAccountNumberForWithdrawal(value);
    }
  };

  return (
    <form
      onSubmit={Formik.handleSubmit}
      className="w-full flex flex-col justify-center items-center p-4 h-full"
    >
      <div className="px-6 my-3 py-3 bg-red-200 text-red-600 ">
        <p className="text-sm">
          Note: N60 will be deducted from your account for this transaction.
        </p>
      </div>
      <div className="grid grid-cols-1 w-full gap-x-2 gap-y-4 content-center">
        <FormInput
          name="Amount"
          type="text"
          onChange={(e: any) => Formik.setFieldValue("amount", e.target.value)}
          value={Formik.values.amount}
          id="amount"
        />
        <div>
          <Label name="Bank" styles={labelStyles} />

          <div>
            <input
              list="browsers"
              onChange={handleDatalistChange}
              className="py-2.5 rounded-lg w-full border border-gray-300 bg-[#D9D9D9] px-4 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <datalist id="browsers" ref={datalistRef}>
              {res?.data?.map((item: any) => {
                return (
                  <option key={item?.uuid} id={item?.name} value={item?.name}>
                    {item?.name}
                  </option>
                );
              })}
            </datalist>
          </div>
        </div>
        <div className=" flex justify-center items-end">
          <FormInput
            name="Destination Account Number"
            type="text"
            loading={validateAccountNumberForWithdrawalDetails.isLoading}
            onChange={(e: any) => {
              Formik.setFieldValue(
                "destinationBankAccountNumber",
                e.target.value
              );
              handleValidateAccountNumberChange(e.target.value);
            }}
            value={Formik.values.destinationBankAccountNumber}
            id="destinationBankAccountNumber"
          />
        </div>
        {accountNumberValidationResponse &&
        accountNumberValidationResponse?.responseCode !== 0 &&
        !validateAccountNumberForWithdrawalDetails.isLoading ? (
          <>
            <p className="-mt-2 text-xs font-thin text-red-500 text-start capitalize">
              {"Invalid Account Number"}
            </p>
          </>
        ) : (
          <p className="-mt-2 text-xs font-thin text-blue-500 text-start capitalize">
            {
              accountNumberValidationResponse?.destinationAccountHolderNameAtBank
            }
          </p>
        )}
        <div>
          <Label name="Wallet" styles={labelStyles} />
          <Select
            fullWidth
            className=" rounded-lg border border-gray-300 bg-[#D9D9D9]"
            sx={{ borderRadius: 2.5, height: 50 }}
            onChange={(e: any) => Formik.setFieldValue("from", e.target.value)}
          >
            <MenuItem value="branchesBalance">Branch Balance</MenuItem>
            <MenuItem value="availableBalance">Available Balance</MenuItem>
          </Select>
        </div>

        {Formik.values?.from === "branchesBalance" ? (
          <div>
            <Label name="Branches" styles={labelStyles} />
            <Select
              className="bg-[#D9D9D9]"
              fullWidth
              sx={{ borderRadius: 2, height: 50 }}
              onChange={(e: any) =>
                Formik.setFieldValue("stationBranch", e.target.value)
              }
            >
              {handledAPIResponse?.map((item: any) => {
                return (
                  <MenuItem key={item?.uuid} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        ) : null}

        <div className="w-full">
          <Label name="Purpose" styles={labelStyles} />
          <textarea
            className="w-full rounded-lg border p-3 bg-[#D9D9D9]"
            placeholder="Add description..."
            rows={3}
            onChange={(e) => Formik.setFieldValue("purpose", e.target.value)}
          />
        </div>
        {showPinInput && (
          <div className="w-full mt-4">
            <PasswordInput
              width="w-full"
              name="Transaction Pin"
              onChange={(e: any) => setPin(e.target.value)}
              value={pin}
              id="pin"
              placeholder="Enter your PIN"
              disabled={isVerifyingPin}
            />
          </div>
        )}
      </div>
      <Button
        text={showPinInput ? "Withdraw" : "Next"}
        disabled={
          widthDrawalResult.isLoading || (showPinInput && isVerifyingPin)
        }
        showModal={
          widthDrawalResult.isLoading || (showPinInput && isVerifyingPin)
        }
        className="h-11 font-bold text-white mt-6 rounded-lg px-5 bg-[#002E66] flex items-center flex-row flex-nowrap justify-center w-full"
        type="submit"
      />
    </form>
  );
};
