import { apiSlice } from "src/api/apiSlice";
import { API_ROUTE, HQ_API_ENPOINTS, RTKTAG } from "src/helpers/Constant";

export const hQManageBranchApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addHqNewBranch: builder.mutation({
      query: (body): any => ({
        url: HQ_API_ENPOINTS.SINGLE_BRANCH,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: RTKTAG.HQ_BRANCH }] as any,
    }),
    updateHqBranchDetails: builder.mutation({
      query: ({ id, ...value }): any => ({
        url: `${HQ_API_ENPOINTS.SINGLE_BRANCH}/${id}`,
        method: "PATCH",
        body: { ...value },
      }),
      invalidatesTags: [{ type: RTKTAG.HQ_BRANCH }] as any,
    }),
    fetchHQBranch: builder.query({
      query: (params) =>
        ({
          url: HQ_API_ENPOINTS.BRANCH,
          params,
        } as any),
      providesTags: [{ type: RTKTAG.HQ_BRANCH }] as any,
    }),
    exportHQBranch: builder.query({
      query: (params) => `${HQ_API_ENPOINTS.BRANCH}?stationHQ=${params.hqId}`,
      providesTags: [{ type: RTKTAG.HQ_BRANCH }] as any,
    }),
    fetchSingleHQBranch: builder.query({
      query: (id) =>
        ({
          url: `${HQ_API_ENPOINTS.SINGLE_BRANCH}?branchId=${id}`,
          params: "populate=manager",
        } as any),
      providesTags: [{ type: RTKTAG.HQ_BRANCH }] as any,
    }),
    fetchAttendentList: builder.query({
      query: (params) => ({ url: `station/pump-attendant`, params } as any),
      providesTags: [{ type: RTKTAG.HQ_BRANCH }] as any,
    }),
    fetchFuelLevel: builder.query({
      query: (params) =>
        ({ url: `${HQ_API_ENPOINTS.FUEL_LEVEL}`, params } as any),
    }),
    fetchMeterReadings: builder.query({
      query: (params) => {
        console.log("params", params);
        return { url: HQ_API_ENPOINTS.METER_READING, params } as any;
      },
    }),
    fetchAttendantSummary: builder.query({
      query: (params) =>
        ({ url: HQ_API_ENPOINTS.ATTENDANT_SUMMARY, params } as any),
    }),
    fetchAttendantProfile: builder.query({
      query: (id) => ({ url: `${HQ_API_ENPOINTS.ATTENDANT}/${id}` } as any),
    }),
    fetchOrderSchedule: builder.query({
      query: (params) =>
        ({ url: HQ_API_ENPOINTS.DEPOT_SCHEDULE_LIST, params } as any),
      providesTags: [{ type: RTKTAG.DEPOT }] as any[],
    }),
    updateWalletPermission: builder.mutation({
      query: ({ id, ...body }: any) =>
        ({
          url: `${HQ_API_ENPOINTS.WALLET_WALLET}/${id}`,
          method: "PATCH",
          body,
        } as any),
      invalidatesTags: [
        {
          type: RTKTAG.HQ_BRANCH,
        },
        {
          type: RTKTAG.BRANCH_WALLET,
        },
      ],
    } as any),

    invoicePayment: builder.mutation({
      query: (body): any => ({
        url: API_ROUTE.DEPOT_ORDER_SINGLE.concat("/invoice/pay"),
        method: "Post",
        body,
      }),

      invalidatesTags: [
        { type: RTKTAG.DEPOT },
        { type: RTKTAG.INVOICE },
      ] as any,
    }),
  }),
});

export const {
  useFetchHQBranchQuery,
  useFetchSingleHQBranchQuery,
  useAddHqNewBranchMutation,
  useUpdateHqBranchDetailsMutation,
  useExportHQBranchQuery,
  useFetchAttendentListQuery,
  useFetchFuelLevelQuery,
  useFetchMeterReadingsQuery,
  useUpdateWalletPermissionMutation,
  useInvoicePaymentMutation,
  useFetchOrderScheduleQuery,
  useFetchAttendantSummaryQuery,
  useFetchAttendantProfileQuery,
} = hQManageBranchApiSlice;
