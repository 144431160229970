import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { useSingleProductListQuery } from "src/api/manageBranchAPISlice";
import { Button } from "src/components/Button";
import { TableLoader } from "src/components/LoaderContainer";
import ViewWalletComp from "src/components/ViewWalletComponent";
import { CurrencyFormatter } from "src/helpers/helperFunction";
import useCustomLocation from "src/hooks/useCustomLocation";
import OrderConfirmation from "./components/OrderConfirmation";
import { FormModal } from "src/components/ModalComp";
const headCells: any[] = [
	{
		id: "createdAt",
		minWidth: 100,
		label: "Request date",
	},
	{
		id: "availableVolume",
		minWidth: 100,
		label: "Available volume",
	},
	{
		id: "requestedVolume",
		minWidth: 170,
		label: "Requested volume",
	},
	{
		id: "name",
		minWidth: 170,
		label: "Product name",
	},
	{
		id: "price",
		minWidth: 170,
		label: "Product price",
	},
	{
		id: "quantity",
		minWidth: 170,
		label: "Product quantity",
	},
	{
		id: "unitOfMeasurement",
		minWidth: 170,
		label: "Product unit",
	},

	{
		id: "amount",
		minWidth: 100,
		label: "Amount",
	},
	{
		id: "request",
		minWidth: 100,
		label: "Send request",
	},
];

export default function OrderProductList({ products }: any) {
	const { slicedPath } = useCustomLocation();
	const [previewData, setPreviewData] = useState({});
	const [page, setPage] = useState(1);

	const singleRequestList = useSingleProductListQuery({
		id: slicedPath[2],
		limit: 6,
		page: page,
		orderBy: "createdAt: desc",
	});

	const handleDepotProductRequestModal = (data: any) => () => {
		setPreviewData(() => data);
	};
	const handledAPIResponse = useMemo(
		() =>
			singleRequestList?.data?.data?.details?.reduce(
				(acc: any, curr: { [x: string]: string | number | any }) => [
					...acc,
					{
						...curr,
						createdAt: format(
							new Date(curr?.product?.createdAt || new Date()),
							"d/M/yyy h:m:s"
						),
						name: curr?.product?.name || "---------",
						price: CurrencyFormatter(curr?.product?.price) || "---------",
						quantity: curr?.product?.quantity || "---------",
						unitOfMeasurement: curr?.product?.unitOfMeasurement || "---------",
						amount: CurrencyFormatter(curr?.amount) || "---------",
						...(singleRequestList?.data?.data?.invoice?.paymentStatus !== "paid"
							? {
									request: Object.keys(singleRequestList?.data?.data).includes(
										"invoice"
									) ? (
										<Button
											className=" bg-primary text-white px-5 py-2 text-sm font-semibold  md:w-[150px] rounded-3xl"
											text="Pay"
											type="button"
											onClick={handleDepotProductRequestModal(curr)}
										/>
									) : singleRequestList?.data?.data?.status === "pending" ? (
										<Button
											className=" bg-yellow-600 text-white px-5 py-2 text-sm font-semibold  md:w-[150px] rounded-3xl"
											text="Pending"
											type="button"
											onClick={handleDepotProductRequestModal(curr)}
										/>
									) : (
										<Button
											className=" bg-primary text-white px-5 py-2 text-sm font-semibold  md:w-[150px] rounded-3xl"
											text="Accept volume"
											type="button"
											onClick={handleDepotProductRequestModal(curr)}
										/>
									),
							  }
							: null),
					},
				],
				[]
			),
		[singleRequestList]
	);

	const props = {
		rows: handledAPIResponse || [],
		headCells,
		handleRowClick: () => {},
		handleChangePage: () => {},
		paginationData: {
			totalPage: handledAPIResponse?.totalPages,
			limit: handledAPIResponse?.limit,
			page: handledAPIResponse?.page,
		},
	};

	// HANDLE EXPORT ALL USER DATA

	// const handleUserData = useMemo(
	// 	() => exportUserResult.currentData?.users?.data,
	// 	[exportUserResult]
	// );

	return (
		<section className=" h-fit">
			<article className="w-full">
				<div className="mt-6">
					<div className="h-fit w-full bg-white mt-6 shadow-lg rounded-t-lg overflow-x-auto">
						<TableLoader
							data={singleRequestList}
							tableData={handledAPIResponse || []}>
							<ViewWalletComp {...props} />
						</TableLoader>
					</div>
				</div>
				{Object.keys(previewData).length > 0 ? (
					<FormModal name="Invoice" onClick={() => setPreviewData({})}>
						<OrderConfirmation
							previewData={previewData}
							invoice={singleRequestList?.data?.data.invoice || {}}
							onClose={() => setPreviewData({})}
						/>
					</FormModal>
				) : null}
			</article>
		</section>
	);
}

// {
// 	Object.keys(previewData).length > 0 ? (
// 		<FormModal name="Invoice" onClick={() => setPreviewData({})}>
// <div className="flex flex-col items-center gap-y-6">
// 	<div>
// 		<img
// 			src={previewData?.depot?.logo?.url}
// 			className="h-[70px] w-[70px]  rounded-full object-cover"
// 			alt=""
// 		/>
// 	</div>
// 	<div className="text-2xl ">
// 		<h6 className="font-bold text-black mt-3">{previewData.depotName}</h6>
// 	</div>
// 	<div className=" ">
// 		{/* {console.log()} */}
// 		{Object.keys(previewData).includes("invoice") ? (
// 			<Button
// 				className=" bg-primary text-white px-5 py-2 text-sm font-semibold  md:w-[150px] rounded-3xl"
// 				text="Pay"
// 				type="button"
// 				// onClick={handleDepotProductRequestModal}
// 			/>
// 		) : (
// 			<Button
// 				className=" bg-primary text-white px-5 py-2 text-sm font-semibold  md:w-[150px] rounded-3xl"
// 				text="Accept volume"
// 				type="button"
// 				// onClick={handleDepotProductRequestModal}
// 			/>
// 		)}
// 	</div>
// 	{/* {console.log(previewData?.details)} */}
// 	<div>
// 		<div className="grid grid-cols-2 justify-content-center py-2">
// 			<SingleListComp
// 				name="Date added"
// 				value={format(
// 					new Date(previewData?.createdAt || null),
// 					"d-M-yyyy h:m:s"
// 				)}
// 			/>
// 			<SingleListComp
// 				name="Products"
// 				value={previewData?.details?.length}
// 			/>
// 		</div>
// 	</div>
// 	{previewData?.details?.map((d: any, i: number) => {
// 		return (
// 			<div className="grid grid-cols-3 px-10 gap-x-4" key={d.id}>
// 				<h2>Quantity: {d?.product?.name}</h2>
// 				<h2>Amount: {d?.amount}</h2>
// 				<h2>Req volume: {d?.requestedVolume}</h2>
// 			</div>
// 		);
// 	})}
// </div>
// 		</FormModal>
// 	) : null;
// }
