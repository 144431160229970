import { type } from "os";

import branchBtn from "src/assets/img/branchbtn.svg";
import walletBtn from "src/assets/img/walletbtn.svg";
import { cardType } from "./alias";

export const cardBtnData: cardType[] = [
  {
    id: 1,
    icon: branchBtn,

    name: "Branches",
  },
  {
    id: 2,
    icon: walletBtn,

    name: "View Wallet",
  },
];

export const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export const datas = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Page H",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Page I",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Page J",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Page K",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Page L",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Page M",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Page N",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Page O",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export const states = [
  "Abuja",
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];

export const filterData: any[] = [
  { id: 7, value: "in_app_transfer", label: "App Transfer" },
  { id: 6, value: "credit", label: "Credit" },
  { id: 8, value: "cash", label: "Cash" },
  { id: 9, value: "debit", label: "Debit" },
  { id: 16, value: "fuel_purchase", label: "Fuel Purchase" },
  { id: 10, value: "premium_feature", label: "Premium Feature" },
  { id: 3, value: "pending", label: "Pending" },
  { id: 11, value: "withdrawal", label: "Withdrawal" },
  { id: 5, value: "successful", label: "Successful" },
];

export const SOURCES = [
  "available_balance",
  "bank_transfer",
  "instant_wallet",
  "card_deposit",
  "cash",
  "user_transfer",
  "hq_transfer",
];

export const CATEGORIES = [
  "withdrawal",
  "in_app_transfer",
  "fuel_purchase",
  "premium_feature",
];

export const TYPES = ["top_up", "remit", "withdrawal", "credit", "debit"];
export const STATUS = ["pending", "successful", "reversed"];
