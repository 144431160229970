const SingleListComp = ({
	name,
	value,
	icon,
}: {
	name: string;
	value: string;
	icon?: any;
}) => (
	<div className="flex flex-col justify-center items-center gap-y-4">
		<div
			className="p-4 rounded-full  bg-gray
		 flex justify-center items-center">
			{icon}
		</div>
		<div>
			<p className="text-sm text-center">{name}</p>
			<h6 className="text-center mt-2 ">{value}</h6>
		</div>
	</div>
);

export default SingleListComp;
