import { Tab, Tabs } from "@mui/material";
import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDepotRequestListQuery } from "src/api/manageBranchAPISlice";
import { TableLoader } from "src/components/LoaderContainer";
import EnhancedTable from "src/components/Table";
import { CurrencyFormatter } from "src/helpers/helperFunction";
import { useAuth } from "src/hooks/useAuth";
import useHandleRowClick from "src/hooks/useHandleRowClick";
import useHandleSelectAllClick from "src/hooks/useHandleSelectAllClick";
import useHandleSingleSelect from "src/hooks/useHandleSingleSelect";
import useIsSelected from "src/hooks/useIsSelected";

import InvoiceList from "./InvoiceList";
import ScheduleList from "./ScheduleList";

export interface HeadCellTypes {
	id: string;
	label: string;
	numeric?: boolean | null;
	minWidth: number;
}

// TABLE HEADER DETAILS
const headCells: readonly HeadCellTypes[] = [
	{
		id: "depotName",
		minWidth: 170,
		label: "Depot name",
	},

	{
		id: "orderCode",
		minWidth: 170,
		label: "Order code",
	},
	{
		id: "totalAmount",
		minWidth: 170,
		label: "Total amount",
	},

	{
		id: "depotPhoneNumber",
		minWidth: 170,
		label: "Depot phone number",
	},
	{
		id: "email",
		minWidth: 170,
		label: "Depot email",
	},
	{
		id: "date",
		minWidth: 170,
		label: "Date",
	},

	{
		id: "status",
		minWidth: 170,
		label: "Status",
	},
];

export default function RequestList() {
	const [pagination, setPagination] = useState(1);

	const { user } = useAuth();

	const navigate = useNavigate();

	const fetchAllBranchResult = useDepotRequestListQuery({
		stationHq: user?.stationHQ?.id,
		page: pagination,
		populate: "depot",
	});

	const handleChangePage = (event: unknown, newPage: number) => {
		setPagination((prev) => newPage);
	};

	const handledAPIResponse = useMemo(() => {
		const response = fetchAllBranchResult?.data;
		const normalizedAPIResponse = response?.data?.data?.reduce(
			(acc: any[], cur: any, i: number) => [
				...acc,
				{
					...cur,
					id: cur?.id,
					date: format(new Date(cur.createdAt), "d/MM/yyyy"),
					totalAmount: CurrencyFormatter(cur?.totalAmount),
					depotName: (
						<div className="flex items-center gap-x- ">
							<img
								src={cur?.depot?.logo?.url}
								alt={cur?.depot?.name}
								className="h-6 w-6 rounded-full object-contin"
							/>
							{cur?.depot?.name}
						</div>
					),
					depotPhoneNumber: cur?.depot?.phoneNumber,
					email: cur?.depot?.email,
					status: (
						<div
							className={`text-center w-fit px-4 py-2 rounded-lg ${
								cur?.status?.toLowerCase() === "pending"
									? "bg-yellow-300"
									: cur.status.toLowerCase() === "successful"
									? "bg-green-300"
									: "bg-red-300 "
							}`}>
							<p
								className={`text-center rounded-lg ${
									cur?.status?.toString().toLowerCase() === "pending"
										? " text-yellow-800"
										: cur.status.toString().toLowerCase() === "successful"
										? " text-green-800"
										: " text-red-800"
								}`}>
								{cur?.status}
							</p>
						</div>
					),
				},
			],
			[]
		);
		return { normalizedAPIResponse, response };
	}, [fetchAllBranchResult]);

	const { handleSelectAllClick, selected, setSelected } =
		useHandleSelectAllClick(handledAPIResponse);
	const { handleClick } = useHandleSingleSelect(selected, setSelected);
	const { handleRowClick } = useHandleRowClick(fn);
	const { isSelected } = useIsSelected(selected);

	// TABLE FILTER TAB

	function fn(data: { [index: string]: string | number }) {
		navigate(`/request/${data?.id}/product/list`, {
			state: data?.orderCode,
		});
	}

	let dataToChildren: { [index: string]: string | number | any } = {
		rows: handledAPIResponse?.normalizedAPIResponse || [],
		headCells,
		handleRowClick,
		isSelected,
		handleClick,
		handleSelectAllClick,
		selected,
		handleChangePage,
		paginationData: {
			totalPage: handledAPIResponse?.response?.data?.totalPages,
			limit: handledAPIResponse?.response?.data?.limit,
			page: handledAPIResponse?.response?.data?.page,
		},
	};

	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<section>
			<article>
				<div className="flex justify-between items-center h-20 ">
					<Tabs value={value} onChange={handleChange} aria-label="Order list">
						<Tab label="Request" tabIndex={0} />
						<Tab label="Invoice" tabIndex={1} />
						<Tab label="Scheduled" tabIndex={2} />
						{/* <Tab label="Completed" tabIndex={3} /> */}
					</Tabs>
				</div>
				{value === 0 ? (
					<div className="h-fit bg-white w-full">
						<TableLoader
							data={fetchAllBranchResult}
							tableData={handledAPIResponse?.normalizedAPIResponse || []}>
							<div className="h-full w-full">
								<div className="relative">
									<EnhancedTable {...dataToChildren} />
								</div>
							</div>
						</TableLoader>
					</div>
				) : null}
				{value === 1 ? <InvoiceList /> : null}
				{value === 2 ? <ScheduleList /> : null}
			</article>
		</section>
	);
}

// REQUEST TO WITHDRAW MODAL
