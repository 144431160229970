import revenue from "src/assets/img/revenue.svg";
import totalTransactions from "src/assets/img/totalTransactions.svg";
import attendant from "src/assets/img/attendant.svg";
import { Fragment } from "react";
import { DashboardCards } from "src/components/Card";
import { CurrencyFormatter } from "src/helpers/helperFunction";
import { useDashboardInfoQuery } from "src/api/authApiSlice";
import { LoaderContainer } from "src/components/LoaderContainer";
import { cardType } from "src/helpers/alias";
import { BarChartComp } from "src/components/Chart";
import { datas } from "src/helpers/data";
import Image from "src/components/Image";

const Dashboard = () => {
  const response: any = useDashboardInfoQuery({});

  const cardData: cardType[] = [
    {
      id: 5,
      icon: totalTransactions,
      amount: Number(response?.data?.data?.transactions || 0),
      name: "Total HQ Transactions",
    },
    // {
    // 	id: 1,
    // 	icon: revenue,
    // 	amount: CurrencyFormatter(
    // 		Number(response?.data?.data?.totalFundsIn || 0)
    // 	),
    // 	name: "Total Revenue",
    // },
    {
      id: 2,
      icon: totalTransactions,
      amount: CurrencyFormatter(response?.data?.data?.totalFundsOut || 0),
      name: "Total Funds Out",
    },
    {
      id: 2,
      icon: totalTransactions,
      amount: CurrencyFormatter(response?.data?.data?.totalFundsIn || 0),
      name: "Total Funds In",
    },

    {
      id: 3,
      icon: attendant,
      amount: response?.data?.data.stationBranches || 0,
      name: "Station Branch",
    },
    {
      id: 3,
      icon: attendant,
      amount: response?.data?.data.stationPumpAttendants || 0,
      name: "Station Attendants",
    },
    {
      id: 4,
      icon: attendant,
      amount: response?.data?.data.stationManagers || 0,
      name: "Station Managers",
    },
  ];

  return (
    <LoaderContainer data={response}>
      <section>
        {/* <LoaderContainer /> */}
        <article className="w-full h-full flex flex-col justify-between overflow-y-auto ">
          <div className="py-3">
            <div className="h-full px-4 pb-4 w-full">
              <div className="h-full grid md:grid-cols-3 lg:grid-cols-4 gap-4 ">
                {cardData.map((dt: any, i: any) => (
                  <Fragment key={i}>
                    <DashboardCards
                      name={dt.name}
                      icon={dt.icon}
                      amount={dt.amount}
                    />
                  </Fragment>
                ))}
              </div>
            </div>
            <div className="mt-auto mb-32 overflow-scroll">
              <div className=" px-7 w-[3300px] h-96  ">
                <BarChartComp data={response?.data?.data?.salesReport} />
              </div>
            </div>
          </div>

          {/* <div className="mt-auto  h-full mb-32">
						
					</div> */}
        </article>
      </section>
    </LoaderContainer>
  );
};

export default Dashboard;
