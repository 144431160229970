import React, { Fragment, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetDepotListQuery } from "src/api/manageBranchAPISlice";

import { LoaderContainer, TableLoader } from "src/components/LoaderContainer";
import { SearchInput } from "src/components/inputs";
import { useAuth } from "src/hooks/useAuth";

import { useDebounce } from "src/hooks/useDebounce";

export default function DepotMarketList() {
	const [filteredValue, setFilteredValue] = useState<string>("");
	const { debouncedValue } = useDebounce(filteredValue, 700);
	const { user } = useAuth();

	const navigate = useNavigate();

	const depotListResult = useGetDepotListQuery({
		...(debouncedValue !== "" && { search: debouncedValue }),
		orderBy: "createdAt:desc",
		// populate: "depot",
	});

	const DepotListData = useMemo(
		() => depotListResult?.data?.data,
		[depotListResult]
	);

	return (
		<section>
			<article className="">
				<div className="flex justify-between items-center mt-6 h-20">
					<div className="flex w-[50%] h-11  max-w-[562px] items-center gap-2 rounded-[15px] border-2 border-[#D0D5DD] bg-[#D9D9D9] px-[18px]">
						<SearchInput
							name="branch-search"
							placeholder="Search for name"
							value={filteredValue}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								const target = e.target;
								setFilteredValue(target.value);
							}}
						/>
					</div>
				</div>
				<TableLoader data={depotListResult} tableData={DepotListData}>
					<div className="grid grid-cols-1 gap-6  lg:grid-cols-3 xxl:grid-cols-4 py-3 mt-1 ">
						{DepotListData?.map((item: any, i: number) => (
							<div
								className="flex flex-col justify-center items-center gap-y-3 py-10 shadow-lg rounded-2xl cursor-pointer"
								onClick={() => {
									navigate(`/market/${item?._id || item?.id}`, {
										state: { name: item?.name },
									});
								}}>
								<div className="h-20 w-24 rounded-full ">
									<img
										src={item?.logo?.url}
										alt="log"
										className="w-full h-full object-contain"
									/>
								</div>

								<div>
									<h2 className="text-xl font-bold text-primary capitalize">
										{item?.name}
									</h2>
								</div>
								<div className="w-3/4">
									<p className="text-sm text-gray-700">{item?.address}</p>
								</div>

								<div className="w-3/4  text-start">
									<ul>
										{item?.products?.map((item: any) => {
											return (
												<li
													key={item?.id}
													className="flex items-center font-bold text-primary gap-4">
													<span className="inline-block h-3 w-3 rounded-full bg-[#d0d0d0]"></span>
													{item?.name}
												</li>
											);
										})}
									</ul>
								</div>
							</div>
						))}
					</div>
				</TableLoader>
			</article>
		</section>
	);
}
