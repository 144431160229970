import { HQ_API_ENPOINTS, RTKTAG } from "src/helpers/Constant";
import { apiSlice } from "src/api/apiSlice";

export const hqSettingsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		updateHqAdminProfile: builder.mutation({
			query: ({ id, ...body }: any): string | any => ({
				url: `${HQ_API_ENPOINTS.SETTINGS}/${id}`,
				method: "PATCH",
				body,
			}),
			invalidatesTags: [{ type: RTKTAG.ADMIN }] as any,
		}),

		updateHqDetails: builder.mutation({
			query: ({ id, ...body }: any): string | any => ({
				url: `${HQ_API_ENPOINTS.HQ_SETTINGS}/${id}`,
				method: "PATCH",
				body,
			}),
			invalidatesTags: [{ type: RTKTAG.ADMIN }] as any,
		}),
	}),
});

export const { useUpdateHqAdminProfileMutation, useUpdateHqDetailsMutation } =
	hqSettingsApiSlice;
