import { Avatar, Pagination } from "@mui/material";
import { useState } from "react";
import { FlagModal, Modal } from "src/components/ModalComp";
import useHandleSelectAllClick from "src/hooks/useHandleSelectAllClick";
import { useNavigate, useParams } from "react-router-dom";
import { Badge } from "src/components/Badge";
import useCustomLocation from "src/hooks/useCustomLocation";
import { useFetchAttendentListQuery } from "src/hq-admin/hq-api/manageHqApiSlice";
import { LoaderContainer } from "src/components/LoaderContainer";

export default function AttendantProfile() {
  const { slicedPath } = useCustomLocation();
  const [filterParam, setFilterParam] = useState({
    page: 1,
    limit: 10,
  });
  const { data: attendantDataResponse, ...attendantDataResponseDetails } =
    useFetchAttendentListQuery({
      stationBranch: slicedPath[2],
      page: filterParam.page,
      limit: 12,
    });
  const params = useParams();

  const [showFlagModal, setShowFlagModal] = useState<boolean>(false);

  const navigate = useNavigate();

  function fn(data: any) {
    return function () {
      navigate(`/manage-attendants/${params?.id}/${data?.id}`, {
        state: `${data?.firstName} ${data?.lastName} `,
        // attendant: `${data?.meta?.assignedPump}`,
      });
    };
  }
  return (
    <section className="w-full h-screen">
      <LoaderContainer data={attendantDataResponseDetails}>
        <article className="w-full h-full pt-6">
          <div className="flex w-full justify-between items-center">
            <h1 className="text-bold w-fit capitalize text-xl font-bold text-black text-center">
              Branch Attendants
            </h1>

            <div className=" w-fit">
              {attendantDataResponse?.totalPages && (
                <Pagination
                  count={attendantDataResponse?.totalPages}
                  page={filterParam?.page}
                  onChange={(e: any, p: any) => {
                    setFilterParam({
                      ...filterParam,
                      page: p,
                    });
                  }}
                  className=" w-fit ml-auto"
                />
              )}
            </div>
          </div>

          <>
            {attendantDataResponse?.data?.length === 0 ? (
              <div className="mt-24">
                <h1>No Attendant(s) Available</h1>
              </div>
            ) : (
              <>
                <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 gap-x-4 gap-y-4 mt-6">
                  {attendantDataResponse?.data?.map((v: any) => (
                    <div
                      key={v?.id}
                      className={`rounded-lg  bg-white py-2 px-2 flex flex-row  justify-start gap-4  hover:border-2 hover:border-[#002E66]  shadow-lg cursor-pointer`}
                      onClick={fn(v)}
                    >
                      <div className="w-[90px] h-[90px] rounded self-center object-fit bg-[#D9D9D9] flex justify-center items-center">
                        {v?.avatar?.url ? (
                          <Avatar
                            sx={{ height: "100%", width: "100%" }}
                            src={v?.avatar?.url}
                          />
                        ) : (
                          <h1 className="text-md text-center font-extrabold">
                            {v.firstName.charAt(0)}
                            {v.lastName.charAt(0)}
                          </h1>
                        )}
                      </div>
                      <div className="basis-[60%] flex flex-col py-2 pl-0 justify-between items-start text-start">
                        <h2 className="text-[#002E66] text-[16px] font-bold">
                          {v?.firstName} {v?.lastName}
                        </h2>
                        <Badge
                          status={
                            v?.accountStatus?.status === "confirmed"
                              ? "active"
                              : v?.accountStatus?.status
                          }
                          type="ACCOUNT_STATUS_OPTIONS"
                        />
                        <p className="text-[#1E1E1E] text-[12px]">
                          {v?.phoneNumber}
                        </p>
                        <p className="text-[#1E1E1E] text-[12px]">{v?.email}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </>

          {/* {showFlagModal && (
            <Modal>
              <FlagModal
                info="Are you sure you want to flag?"
                onClose={() => setShowFlagModal(false)}
                onConfirmation={() => console.log(selected)}
              />
            </Modal>
          )} */}
        </article>
      </LoaderContainer>
    </section>
  );
}

// milk brother alpha tooth zebra cry they puzzle drip despair master under
