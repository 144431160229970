import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components/Button";
import {
  SearchInput,
  SelectInput,
  SelectInputNew,
} from "src/components/inputs";
import { TableLoader } from "src/components/LoaderContainer";
import { FlagModal, FormModal, Modal } from "src/components/ModalComp";
import EnhancedTable from "src/components/Table";

import { FormType, ManageBranchType } from "src/helpers/alias";
import {
  CurrencyFormatter,
  handleNotification,
  SuccessNotification,
} from "src/helpers/helperFunction";

import { useAuth } from "src/hooks/useAuth";
import { useDebounce } from "src/hooks/useDebounce";
import useHandleRowClick from "src/hooks/useHandleRowClick";
import useHandleSelectAllClick from "src/hooks/useHandleSelectAllClick";
import useHandleSingleSelect from "src/hooks/useHandleSingleSelect";
import useIsSelected from "src/hooks/useIsSelected";
import {
  useAddHqNewBranchMutation,
  useExportHQBranchQuery,
  useFetchHQBranchQuery,
} from "src/hq-admin/hq-api/manageHqApiSlice";
import { AddNewBranch } from "./Components";
import { states } from "src/helpers/data";
import { Badge } from "src/components/Badge";
import { useFetchAllStatesQuery } from "src/api/statesApiSlice";

export interface HeadCellTypes {
  id: string;
  label: string;
  numeric?: boolean | null;
  minWidth: number;
}

// TABLE HEADER DETAILS
const headCells: readonly HeadCellTypes[] = [
  {
    id: "status",
    minWidth: 160,
    label: "Branch Status",
  },
  {
    id: "suspend",
    minWidth: 160,
    label: "Manager Status",
  },
  {
    id: "name",
    minWidth: 170,
    label: "Name",
  },
  {
    id: "state",
    minWidth: 100,
    label: "State",
  },
  {
    id: "pumpCount",
    minWidth: 170,
    label: "Pump Count",
  },

  {
    id: "address",
    minWidth: 170,
    label: "Address",
  },
];

const initialValues = {
  // stationHQ: routePath?.id,
  name: "",
  phoneNumber: "",
  location: {
    state: "",
    latitude: "",
    longitude: "",
    address: "",
  },
  branchManager: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
  },
};

export default function ManageHQBranch() {
  const [pagination, setPagination] = useState({ newPage: 1 });
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [AddNewBranchRequest, addNewBranchResult] = useAddHqNewBranchMutation();
  // console.log(user);

  const [filterParam, setFilterParam] = useState({
    search: "",
    state: "",
  });
  const { debouncedValue: stateDebouncedValue } = useDebounce(
    filterParam.state,
    700
  );
  const { debouncedValue: searchDebouncedValue } = useDebounce(
    filterParam.search,
    700
  );
  const fetchAllBranchResult = useFetchHQBranchQuery({
    // stationHQ: hqId,
    ...(searchDebouncedValue && { search: searchDebouncedValue }),
    ...(stateDebouncedValue && { state: stateDebouncedValue }),
    page: pagination.newPage,
    orderBy: "createdAt:desc",
    populate: "manager",
  });

  const statesResult = useFetchAllStatesQuery(null);

  const handledStatesAPIResponse = useMemo(() => {
    const data = statesResult?.currentData || [];
    let neededData: {
      id: string;
      label: string;
      value: string;
    }[] = [];

    if (data) {
      for (const iterator of data) {
        neededData = [
          ...neededData,
          {
            id: iterator.id,
            label: iterator?.state?.name,
            value: iterator?.state?.name?.toLowerCase(),
          },
        ];
      }
    }
    return neededData;
  }, [statesResult]);

  const exportBranchResult = useExportHQBranchQuery({});

  async function addNewBranchFunct(values: any) {
    try {
      await AddNewBranchRequest(values).unwrap();

      SuccessNotification("Branch Added");
      setShowAddModal(() => false);
    } catch (error: any) {
      setShowAddModal(() => false);
      handleNotification(error);
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination((prev) => {
      return { ...prev, newPage };
    });
  };

  // console.log(fetchAllBranchResult, "gfhjk");
  const handledAPIResponse = useMemo(() => {
    const response = fetchAllBranchResult?.data;
    const normalizedAPIResponse = response?.data?.data?.reduce(
      (acc: ManageBranchType[], cur: ManageBranchType) => [
        ...acc,
        {
          id: cur?.id,
          date: format(new Date(cur.createdAt), "d/MM/yyyy"),
          name: cur?.name,
          pumpCount: cur?.config.pumpCount || 0,
          phoneNumber: cur?.phoneNumber,
          logo: cur?.coverImage?.url,
          status: cur?.status ? (
            <Badge
              type="BRANCH_STATION_STATUS_OPTIONS"
              status={cur?.status === "available" ? "open" : "closed"}
            />
          ) : (
            "----------"
          ),
          suspend: (
            <Badge
              type="ACCOUNT_STATUS_OPTIONS"
              status={
                cur?.manager?.accountStatus.status === "confirmed"
                  ? "active"
                  : "suspended"
              }
            />
          ),

          lga: cur?.location?.lga,
          address: cur?.location?.address,

          state: cur?.location?.state,
        },
      ],
      []
    );
    return { normalizedAPIResponse, response };
  }, [fetchAllBranchResult]);

  const { handleSelectAllClick, selected, setSelected } =
    useHandleSelectAllClick(handledAPIResponse);
  const { handleClick } = useHandleSingleSelect(selected, setSelected);
  const { handleRowClick } = useHandleRowClick(fn);
  const { isSelected } = useIsSelected(selected);

  const navigate = useNavigate();

  // TABLE FILTER TAB

  function fn(data: { [index: string]: string | number }) {
    navigate(`/manage-branch/${data?.id}`, { state: data.name });
  }

  let dataToChildren: { [index: string]: string | number | any } = {
    rows: handledAPIResponse?.normalizedAPIResponse || [],
    headCells,
    handleRowClick,
    isSelected,
    handleClick,
    handleSelectAllClick,
    selected,
    handleChangePage,
    paginationData: {
      totalPage: handledAPIResponse?.response?.data?.totalPages,
      limit: handledAPIResponse?.response?.data?.limit,
      page: handledAPIResponse?.response?.data?.page,
    },
  };

  // const handleExportData = useMemo(
  // 	() => exportBranchResult?.currentData,
  // 	[exportBranchResult]
  // );

  //BRANCH REGISTERED IS MORE THAN FIVE(5)
  const handleAddMoreBranch = () => {
    setShowAddModal(true);
  };

  return (
    <section>
      <article>
        <div className="flex w-full justify-between items-center mt-6 h-20">
          <div className=" flex gap-3 flex-wrap  lg:mb-0">
            <SearchInput
              name="branch-search"
              placeholder="Search for branch"
              value={filterParam.search}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const target = e.target;
                setFilterParam({ ...filterParam, search: target.value });
              }}
            />
            <SelectInputNew
              filteredValue={filterParam?.state || ""}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setFilterParam({ ...filterParam, state: e.target.value })
              }
              iniText="All states"
              tabData={handledStatesAPIResponse}
            />
          </div>

          <Button
            text="Add Branch"
            type="button"
            showIcon={true}
            onClick={handleAddMoreBranch}
          />
          {/* <div className="w-[109px] h-11">
							{/* <CSVLink filename="branch_data" data={handleExportData ?? []}>
								<Button
									text="Export"
									className="h-full w-full font-bold bg-[#D0D5DD] rounded-lg hover: text-[#002E66] flex items-center justify-center"
									type="button"
									showIcon={false}
								/>
							</CSVLink> 
						</div> */}
        </div>
        <div className="h-fit bg-white w-full">
          <TableLoader
            data={fetchAllBranchResult}
            tableData={handledAPIResponse?.normalizedAPIResponse || []}
          >
            <div className="h-full w-full">
              <div className="relative">
                <EnhancedTable
                  {...dataToChildren}
                  showImage={true}
                  imageCell="logo"
                />
              </div>
            </div>
          </TableLoader>

          {/* FLAG A HQ */}
          {/* {showPremiumModal && (
						<Modal>
							<FlagModal
								info="Sorry You have exceeded the number of branches you can add on the free mode!"
								onClose={() => setShowPremiumModal(false)}
								onConfirmation={() => console.log(selected)}
							/>
						</Modal>
					)} */}
          {showAddModal ? (
            <FormModal
              name="Add branch"
              onClick={() => setShowAddModal((prevState) => !prevState)}
            >
              <AddNewBranch
                initalValue={initialValues}
                makeApiRequest={(values: any) => addNewBranchFunct(values)}
                apiResult={addNewBranchResult}
              />
            </FormModal>
          ) : null}
        </div>
      </article>
    </section>
  );
}

// REQUEST TO WITHDRAW MODAL
