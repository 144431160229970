import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDeleteInvoiceMutation } from "src/api/manageBranchAPISlice";
import { Button } from "src/components/Button";
import { TableLoader } from "src/components/LoaderContainer";
import EnhancedTable from "src/components/Table";
import {
	CurrencyFormatter,
	handleNotification,
	SuccessNotification,
} from "src/helpers/helperFunction";

import { useAuth } from "src/hooks/useAuth";
import { useDebounce } from "src/hooks/useDebounce";
import useHandleRowClick from "src/hooks/useHandleRowClick";
import useHandleSelectAllClick from "src/hooks/useHandleSelectAllClick";
import useHandleSingleSelect from "src/hooks/useHandleSingleSelect";
import useIsSelected from "src/hooks/useIsSelected";
import { useFetchOrderScheduleQuery } from "src/hq-admin/hq-api/manageHqApiSlice";

export interface HeadCellTypes {
	id: string;
	label: string;
	numeric?: boolean | null;
	minWidth: number;
}

// TABLE HEADER DETAILS
const headCells: readonly HeadCellTypes[] = [
	{
		id: "date",
		minWidth: 170,
		label: "Date",
	},
	{
		id: "invoiceNumber",
		minWidth: 170,
		label: "Order code",
	},
	{
		id: "totalAmount",
		minWidth: 170,
		label: "Total amount",
	},
	{
		id: "tax",
		minWidth: 170,
		label: "Tax deducted",
	},
	{
		id: "invoiceNote",
		minWidth: 170,
		label: "Invoice note",
	},
	{
		id: "discount",
		minWidth: 170,
		label: "Discount",
	},
	{
		id: "dueDate",
		minWidth: 170,
		label: "Due date",
	},

	{
		id: "paymentStatus",
		minWidth: 170,
		label: "Payment Status",
	},
	{
		id: "delete",
		minWidth: 170,
		label: "Delete",
	},
];

export default function ScheduleList() {
	const [filteredValue, setFilteredValue] = useState<string>("");
	const { debouncedValue } = useDebounce(filteredValue, 700);
	const [pagination, setPagination] = useState({ newPage: 1 });
	const navigate = useNavigate();

	const [deleteInvoice, deleteInvoiceResult] = useDeleteInvoiceMutation();
	const getDepotScheduleQuery = useFetchOrderScheduleQuery({
		// q: debouncedValue,
		// stationHq: user?.stationHQ,
		...(pagination.newPage === 1 && { page: pagination.newPage }),
	});

	async function deleteInvoiceFunct(id: string) {
		try {
			const response = await deleteInvoice(id).unwrap();
			if (response) {
				SuccessNotification(response?.data?.message);
			}
		} catch (error: any) {
			handleNotification(error);
		}
	}
	//1YcE#L@n8dsOp0
	const handleChangePage = (event: unknown, newPage: number) => {
		setPagination((prev) => {
			return { ...prev, newPage };
		});
	};

	const handledAPIResponse = useMemo(() => {
		const response = getDepotScheduleQuery?.data;
		const normalizedAPIResponse = response?.data?.data?.reduce(
			(acc: any[], cur: any, i: number) => [
				...acc,
				{
					...cur,
					id: cur?.id,
					date: format(new Date(cur.createdAt), "d/MM/yyyy"),
					totalAmount: CurrencyFormatter(cur?.totalAmount),
					tax: CurrencyFormatter(cur?.tax),
					discount: CurrencyFormatter(cur?.discount),
					paymentStatus: cur?.paymentStatus,
					dueDate: format(new Date(cur?.dueDate), "d/M/yyyy h:m:s"),

					...(cur?.paymentStatus !== "paid"
						? {
								delete: (
									<Button
										className=" bg-red-800 text-white px-5 py-2 text-sm font-semibold  md:w-[150px] rounded-3xl"
										text="Delete"
										type="button"
										showModal={deleteInvoiceResult?.isLoading}
										onClick={() => deleteInvoiceFunct(cur?.id)}
									/>
								),
						  }
						: null),
				},
			],
			[]
		);
		return { normalizedAPIResponse, response };
	}, [getDepotScheduleQuery]);

	const { handleSelectAllClick, selected, setSelected } =
		useHandleSelectAllClick(handledAPIResponse);
	const { handleClick } = useHandleSingleSelect(selected, setSelected);
	const { handleRowClick } = useHandleRowClick(fn);
	const { isSelected } = useIsSelected(selected);

	// TABLE FILTER TAB

	function fn(data: { [index: string]: string | number }) {
		return navigate(`/invoice/${data?.id}`);
	}

	let dataToChildren: { [index: string]: string | number | any } = {
		rows: handledAPIResponse?.normalizedAPIResponse || [],
		headCells,
		handleRowClick,
		isSelected,
		handleClick,
		handleSelectAllClick,
		selected,
		handleChangePage,
		paginationData: {
			totalPage: handledAPIResponse?.response?.data?.totalPages,
			limit: handledAPIResponse?.response?.data?.limit,
			page: handledAPIResponse?.response?.data?.page,
		},
	};

	return (
		<section>
			<article>
				<div className="flex justify-between items-center mt-6 h-20"></div>
				<div className="h-fit bg-white w-full">
					<TableLoader
						data={getDepotScheduleQuery}
						tableData={handledAPIResponse?.normalizedAPIResponse || []}>
						<div className="h-full w-full">
							<div className="relative">
								<EnhancedTable {...dataToChildren} />
							</div>
						</div>
					</TableLoader>
				</div>
			</article>
		</section>
	);
}

// REQUEST TO WITHDRAW MODAL
