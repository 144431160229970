import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import {
  CurrencyFormatter,
  handleFormatDate,
} from "src/helpers/helperFunction";
import {
  useBankListQuery,
  useValidateAccountNumberForWithdrawalMutation,
} from "src/hq-admin/hq-api/hqTransactionApiSlice";

type RequestDetailsType = {
  name: string;
  amount: number;
  date: Date;
  payload?: any;
  status?: string;
  updatedAt?: Date;
  desc?: string;
};
export default function RequestDetails({
  name,
  amount,
  date,
  desc,
  updatedAt,
  payload,
  status,
}: RequestDetailsType) {
  // useEffect(() => {
  //   if (payload?.destinationBankAccountNumber && payload?.destinationBankUUID)
  //     handleValidateBankName();
  // }, [payload?.destinationBankAccountNumber, payload?.destinationBankUUID]);

  return (
    <div className="w-full flex justify-between items-center  py-2  h-full text-sm gap-1">
      <div className="space-y-1">
        <div>
          <h1 className="text-lightgray">Branch Name</h1>
          <p>{name}</p>
        </div>

        <div>
          <h1 className="text-lightgray">Amount</h1>
          <p>{CurrencyFormatter(amount)}</p>
        </div>

        {desc ? (
          <div>
            <h1 className="text-lightgray">Description</h1>
            <p className=" max-w-[750px]">{desc}</p>
          </div>
        ) : null}
      </div>
      <div>
        <p>Requested: {handleFormatDate(date)}</p>
        {status === "approved" ? (
          <p>Approved: {handleFormatDate(updatedAt)}</p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
