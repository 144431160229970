import { HighlightOffOutlined, Print } from "@mui/icons-material";
import { splitByUpperCase } from "src/helpers/helperFunction";
import { Modal } from "./ModalComp";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { format } from "date-fns";
import { useReactToPrint } from "react-to-print";
import DownloadableReceipt from "./DownloadableReciept";

export default function ReceiptCard(props: any) {
  const transactionStates = ["", false, undefined, null, true];
  const componentRef = useRef<any>();

  const editAllData = useCallback((...val: any[]) => {
    return typeof val[1] === "string" ? val?.[1]?.replace("_", " ") : val[1];
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Modal>
      <div style={{ display: "none" }}>
        <DownloadableReceipt data={props?.data} ref={componentRef} />
      </div>

      <div className="absolute w-full h-full right-0 top-0 bg-[rgba(0,0,0,0.5)] flex justify-center items-center">
        <div className="w-[70%] mx-auto flex flex-col justify-center rounded-[20px] pb-10 bg-white">
          <div className="w-full px-10 pt-2 pb-2 mt-2 font-bold text-xl text-[#002E66] flex justify-between items-center">
            <h1>Transaction Details</h1>
            <button onClick={() => props.setShowModal(false)} disabled={false}>
              <HighlightOffOutlined
                fontSize="large"
                className="text-black cursor-pointer"
              />
            </button>
          </div>
          <div>
            <hr />
          </div>

          <div className="w-full h-full rounded-lg text-[14px]  py-6 max-h-[70vh] overflow-y-auto">
            <div className="grid grid-cols-2 gap-x-1 gap-y-7 py-4 md:gap-x-2 text-start px-4 lg:px-16">
              {Object.entries(props?.data)?.map((dt: any) => {
                if (transactionStates.includes(dt[1])) {
                  return null;
                }
                return (
                  <div key={dt.id}>
                    <h2 className="text-black capitalize">
                      {splitByUpperCase(dt[0])}
                    </h2>

                    <span className="block  w-20 my-0.5 rounded-lg"></span>
                    <h2 className="text-[#002E66]">
                      {editAllData(dt[0], dt[1])}
                    </h2>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="ml-10 px-6 mt-5">
            <button
              onClick={handlePrint}
              className="py-2 px-4 bg-primary text-white rounded-md"
            >
              Save/Download receipt
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
