import { SetStateAction, useCallback, useMemo, useState } from "react";
import { Button } from "src/components/Button";
import { FormModal } from "src/components/ModalComp";
import {
  CurrencyFormatter,
  ErrorNotification,
  SuccessNotification,
} from "src/helpers/helperFunction";
import Wallet from "src/assets/img/walletbtn.svg";
import {
  useApproveWithdrawalRequestMutation,
  useBankListQuery,
  useBankQuery,
  useGetAllWidthdrawRequestQuery,
  useValidateAccountNumberForWithdrawalMutation,
  useVerifyTransactionPinMutation,
} from "src/hq-admin/hq-api/hqTransactionApiSlice";
import { LoaderContainer } from "src/components/LoaderContainer";
import { format } from "date-fns";
import { Tabs, Tab, Pagination } from "@mui/material";
import RequestDetails from "./components/RequestDetails";
import NotificationIndicator from "./components/Notification";
import { PasswordInput } from "src/components/inputs";
import { useAuth } from "src/hooks/useAuth";
import { useParams } from "react-router-dom";

const WithDrawal = () => {
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [value, setValue] = useState(0);
  const { user } = useAuth();
  const params = useParams();

  const [pendingfilterParam, setPendingFilterParam] = useState({
    page: 1,
    limit: 10,
  });
  const [approvedfilterParam, setApprovedFilterParam] = useState({
    page: 1,
    limit: 10,
  });
  const [rejectedfilterParam, setRejectedFilterParam] = useState({
    page: 1,
    limit: 10,
  });

  const { data: allPendingWithdrawalRequest } = useGetAllWidthdrawRequestQuery({
    populate: "stationBranch",
    stationBranch: params?.id,
    status: "pending",
    orderBy: "createdAt:desc",
    page: pendingfilterParam.page,
  });

  const { data: allApprovedWithdrawalRequest } = useGetAllWidthdrawRequestQuery(
    {
      populate: "stationBranch",
      stationBranch: params?.id,
      status: "approved",
      orderBy: "createdAt:desc",
      page: approvedfilterParam.page,
    }
  );

  const { data: allRejectedWithdrawalRequest } = useGetAllWidthdrawRequestQuery(
    {
      populate: "stationBranch",
      stationBranch: params?.id,
      status: "rejected",
      orderBy: "createdAt:desc",
      page: rejectedfilterParam.page,
    }
  );

  const allWithdrawalRequestQuery = useGetAllWidthdrawRequestQuery({
    populate: "stationBranch",
    stationBranch: params?.id,
    orderBy: "createdAt:desc",
  });

  const branchWithdrawRequest = useCallback(
    (status: string) =>
      allWithdrawalRequestQuery?.data?.data?.data?.filter(
        (item: any) => item?.status === status
      ),
    [allWithdrawalRequestQuery]
  );

  const handleWidthDrawal = (item: any) => {
    setShowNotification((prevState) => !prevState);

    setSelectedItem(item);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <section>
      <Tabs value={value} onChange={handleChange} aria-label="Order list">
        <Tab
          label={`Pending (${
            allPendingWithdrawalRequest?.data?.totalData || 0
          })`}
          tabIndex={0}
        />
        <Tab
          label={`Approved (${
            allApprovedWithdrawalRequest?.data?.totalData || 0
          })`}
          tabIndex={1}
        />
        <Tab
          label={`Rejected (${
            allRejectedWithdrawalRequest?.data?.totalData || 0
          })`}
          tabIndex={2}
        />
        {/* <Tab label="Completed" tabIndex={3} /> */}
      </Tabs>
      <LoaderContainer data={allWithdrawalRequestQuery}>
        <article>
          {value === 0 ? (
            <>
              {allPendingWithdrawalRequest?.data?.totalData > 0 ? (
                <>
                  {allPendingWithdrawalRequest?.data?.data?.map((item: any) => {
                    return (
                      <button
                        onClick={() => handleWidthDrawal(item)}
                        className="w-full py-5 mt-3 shadow-lg bg-white rounded-lg flex items-center gap-10 px-6 font-semibold text-primary text-sm text-start relative"
                      >
                        <img src={Wallet} alt="wallet" />
                        <RequestDetails
                          payload={item?.payload}
                          name={item?.stationBranch?.name}
                          amount={item?.payload?.amount}
                          date={item?.createdAt}
                        />
                        <NotificationIndicator status="pending" />
                      </button>
                    );
                  })}
                  <Pagination
                    count={allPendingWithdrawalRequest?.data?.totalPages}
                    page={pendingfilterParam?.page}
                    onChange={(e: any, p: any) => {
                      setPendingFilterParam({
                        ...pendingfilterParam,
                        page: p,
                      });
                    }}
                    className="py-4 w-fit ml-auto"
                  />
                </>
              ) : (
                <div className="w-full h-56  flex items-center justify-center font-bold ">
                  No pending withdrawal Request Available
                </div>
              )}
            </>
          ) : null}
          {value === 1 ? (
            <>
              {allApprovedWithdrawalRequest?.data?.totalData > 0 ? (
                <>
                  {allApprovedWithdrawalRequest?.data?.data.map((item: any) => {
                    return (
                      <button className="w-full py-5 mt-3 shadow-lg bg-white rounded-lg flex items-center gap-10 px-6 font-semibold text-primary text-sm text-start relative">
                        <img src={Wallet} alt="wallet" />
                        <RequestDetails
                          name={item?.stationBranch?.name}
                          amount={item?.payload?.amount}
                          desc={item?.payload?.purpose}
                          date={item?.createdAt}
                          updatedAt={item?.updatedAt}
                          status={item?.status}
                        />
                        <NotificationIndicator status="approved" />
                      </button>
                    );
                  })}
                  <Pagination
                    count={allApprovedWithdrawalRequest?.data?.totalPages}
                    page={approvedfilterParam?.page}
                    onChange={(e: any, p: any) => {
                      setApprovedFilterParam({
                        ...approvedfilterParam,
                        page: p,
                      });
                    }}
                    className="py-4 w-fit ml-auto"
                  />
                </>
              ) : (
                <div className="w-full h-56  flex items-center justify-center font-bold ">
                  No approved withdrawal Request Available
                </div>
              )}
            </>
          ) : null}
          {value === 2 ? (
            <>
              {allRejectedWithdrawalRequest?.data?.totalData > 0 ? (
                <>
                  {allRejectedWithdrawalRequest?.data?.data?.map(
                    (item: any) => {
                      return (
                        <button className="w-full py-5 mt-3 shadow-lg bg-white rounded-lg flex items-center gap-10 px-6 font-semibold text-primary text-sm text-start relative">
                          <img src={Wallet} alt="wallet" />
                          <RequestDetails
                            name={item?.stationBranch?.name}
                            amount={item?.payload?.amount}
                            date={item?.createdAt}
                          />
                          <NotificationIndicator status="rejected" />
                        </button>
                      );
                    }
                  )}
                  <Pagination
                    count={allRejectedWithdrawalRequest?.data?.totalPages}
                    page={rejectedfilterParam?.page}
                    onChange={(e: any, p: any) => {
                      setRejectedFilterParam({
                        ...rejectedfilterParam,
                        page: p,
                      });
                    }}
                    className="py-4 w-fit ml-auto"
                  />
                </>
              ) : (
                <div className="w-full h-56  flex items-center justify-center font-bold ">
                  No declined Request
                </div>
              )}
            </>
          ) : null}
        </article>
      </LoaderContainer>

      {showNotification ? (
        <FormModal
          name="Withdrawal request"
          onClick={() => setShowNotification((prevState) => !prevState)}
        >
          <WidthDrawModal
            toggleState={() => setShowNotification((prevState) => !prevState)}
            selectedItem={selectedItem}
          />
        </FormModal>
      ) : null}
    </section>
  );
};

export default WithDrawal;

const WidthDrawModal = ({ selectedItem, toggleState }: any) => {
  console.log(selectedItem);
  const [approveWidthdrawalMutation, widthdrawalRequest] =
    useApproveWithdrawalRequestMutation();
  const [verifyTransactionPin, { isLoading: isVerifyingPin }] =
    useVerifyTransactionPinMutation();

  const { data: bankApiResponse, ...bankApiResponseDetails } = useBankQuery(
    selectedItem?.payload?.destinationBankUUID,
    { skip: !selectedItem?.payload?.destinationBankUUID }
  );

  const [banckRes, setbanckRes] = useState<any>(null);
  const [
    validateAccountNumberForWithdrawal,
    { ...validateAccountNumberForWithdrawalDetails },
  ] = useValidateAccountNumberForWithdrawalMutation();

  const handleValidateBankName = async () => {
    if (
      selectedItem?.payload?.destinationBankAccountNumber &&
      selectedItem?.payload?.destinationBankUUID
    ) {
      const res = await validateAccountNumberForWithdrawal({
        accountNumber: `${selectedItem?.payload?.destinationBankAccountNumber}`,
        bankId: selectedItem?.payload?.destinationBankUUID,
      });
      setbanckRes(res);
    }
  };

  const [approvedState, setApprovedState] = useState(false);
  const [pin, setPin] = useState("");
  const [isPinVerified, setIsPinVerified] = useState(false);
  const [isEnterPin, setIsEnterPin] = useState(false);
  const [toUpdate, setToUpdate] = useState(false);

  const handleAction = async (isApproved: boolean) => {
    setToUpdate(isApproved);
    setIsEnterPin(true);
  };

  const handleConfirmPin = async () => {
    try {
      const response: any = await verifyTransactionPin({
        pin: pin,
      });

      if (response.data.error) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    } finally {
    }
  };

  const handleApprovedRequest = async (isApproved: boolean) => {
    const verifyPinResponse = await handleConfirmPin();

    if (!verifyPinResponse) {
      ErrorNotification("Invalid Pin");
      return;
    }

    setApprovedState(isApproved);
    try {
      const response: any = await approveWidthdrawalMutation({
        id: selectedItem?.id,
        body: { status: isApproved ? "approved" : "rejected" },
      }).unwrap();
      SuccessNotification(
        isApproved ? response.message : "Request declined successfully"
      );
    } catch (error: any) {
      ErrorNotification(error?.data?.message || "An error occurred");
      console.error(error, "error");
    } finally {
      setIsEnterPin(false);
    }
    toggleState();
  };

  const verifyPin = () => {
    if (pin.length === 4 && pin === "1234") {
      // Replace "1234" with the logic or API call to verify the PIN
      setIsPinVerified(true);
      alert("PIN verified successfully!");
    } else {
      alert("Invalid PIN. Please try again.");
      setIsPinVerified(false);
    }
  };

  return (
    <div className="w-full flex flex-col justify-center py-4 px-6 h-full text-sm gap-6">
      <div>
        <h1 className="text-lightgray">Branch Name</h1>
        <p>{selectedItem?.stationBranch?.name || "Name not available"}</p>
      </div>
      <div>
        <h1 className="text-lightgray">Description</h1>
        <p>{selectedItem?.payload?.purpose}</p>
      </div>
      <div>
        <h1 className="text-lightgray">Amount</h1>
        <p>{CurrencyFormatter(selectedItem?.payload?.amount)}</p>
      </div>
      <div>
        <h1 className="text-lightgray">Request Date</h1>
        <p>{format(new Date(selectedItem?.createdAt), "d/MM/yyyy h:m:s a")}</p>
      </div>
      <div>
        <h1 className="text-lightgray">Transaction details</h1>
        {selectedItem?.payload?.systemCode ? (
          <p>Reciever System Code: {selectedItem?.payload?.systemCode} </p>
        ) : (
          <>
            {/* {handleValidateBankName()} */}
            {bankApiResponseDetails.isLoading ? (
              <svg
                aria-hidden="true"
                role="status"
                className="inline w-4 h-4 me-3 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
            ) : (
              <>
                {" "}
                <p>Bank name: {bankApiResponse?.data?.name} </p>
                <p>
                  Account Number:{" "}
                  {selectedItem?.payload?.destinationBankAccountNumber}
                </p>
              </>
            )}
          </>
        )}
      </div>

      {isEnterPin && (
        <div>
          <h1 className=" mt-0">Enter transaction pin to proceed</h1>
          <div className="flex flex-col w-full items-center gap-4 mt-5">
            <div className="w-full -mt-4">
              <PasswordInput
                width="w-full"
                id={"pin"}
                name={"Pin"}
                inputName={"pin"}
                type={"text"}
                onChange={(e: any) => {
                  setPin(e.target.value);
                }}
                value={pin}
                disabled={isVerifyingPin}
                // error={_v.error}
                // touched={_v.touched}
              />
            </div>
            <div className="flex w-full justify-between items-center">
              <div className=" w-[48%]   h-[41px] mb-[19px]">
                <Button
                  onClick={() => handleApprovedRequest(toUpdate ? true : false)}
                  text={toUpdate ? "Process Approval" : "Process Decline"}
                  className="h-[41px]  w-full font-bold bg-primary rounded-full text-white flex items-center justify-center"
                  type="button"
                  disabled={isVerifyingPin}
                  showModal={widthdrawalRequest.isLoading || isVerifyingPin}
                />
              </div>
              <div className=" w-[48%]  h-[41px] mb-[19px]">
                <Button
                  onClick={() => {
                    setIsEnterPin(false);
                  }}
                  disabled={isVerifyingPin}
                  text="Cancel"
                  className="h-[41px]  w-full font-bold bg-primary rounded-full text-white flex items-center justify-center"
                  type="button"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {!isEnterPin && (
        <div className="flex w-full justify-between items-center">
          <Button
            onClick={() => handleAction(true)}
            text="Approve"
            className="h-[41px] w-[48%] font-bold  bg-primary rounded-full text-white flex items-center justify-center"
            type="button"
            showIcon={false}
            showModal={widthdrawalRequest.isLoading && approvedState}
          />
          <Button
            text="Decline"
            className="h-[41px] w-[48%]  font-bold bg-red-600 border border-red-600 text-white rounded-full flex items-center justify-center"
            type="button"
            showIcon={false}
            showModal={widthdrawalRequest.isLoading && !approvedState}
            onClick={() => handleAction(false)}
          />
        </div>
      )}

      {/* <div className="w-full h-[41px]">
        <Button
          onClick={() => handleAction(false)}
          text="Decline"
          className="h-full w-full font-bold bg-red-600 border border-red-600 text-white rounded-full flex items-center justify-center"
          type="button"
          showModal={widthdrawalRequest.isLoading && !approvedState}
        />
      </div> */}
    </div>
  );
};
