import React, { Fragment, useState } from "react";
import {
  cardBtnType,
  FormType,
  TransactionsType,
  UpdateFormType,
} from "src/helpers/alias";
import User from "src/assets/img/User.svg";
import HqWallet from "src/assets/img/HQWallet.svg";
import Attendant from "src/assets/img/Attendanticon.svg";
import MainProduct from "src/assets/img/MainProduct.svg";
import Wallet from "src/assets/img/walletbtn.svg";
import Rating from "src/assets/img/Ratings.svg";
import { CardButton } from "src/components/Card";
import { useMemo } from "react";
import ProfileCard from "src/components/ProfileCard";
import { LoaderContainer, TableLoader } from "src/components/LoaderContainer";
import useCustomLocation from "src/hooks/useCustomLocation";
import AddAmenityForm from "src/components/AddAmenities";

import {
  Category,
  CurrencyFormatter,
  handleNotification,
  splitByUpperCase,
  SuccessNotification,
  truncateString,
} from "src/helpers/helperFunction";
import AttendantProfile from "src/screens/dashboard/pages/Manage-branch/AttendantProfile";
import BranchReview from "src/screens/dashboard/pages/Manage-branch/BranchReview";
import OtherProduct from "src/assets/img/OtherProduct.svg";
import {
  useFetchAttendentListQuery,
  useFetchSingleHQBranchQuery,
  useUpdateHqBranchDetailsMutation,
  useUpdateWalletPermissionMutation,
} from "src/hq-admin/hq-api/manageHqApiSlice";
import { FlagModal, FormModal, Modal } from "src/components/ModalComp";
// import { AddNewBranch } from "./Components";
import Transaction from "../Transaction";
import { ProductCard } from "src/components/ProductCard";
import { Button } from "src/components/Button";
import { FormInput, SearchInput } from "src/components/inputs";
import { useFormik } from "formik";
import { format } from "date-fns";
import {
  useBranchWalletQuery,
  useGetAllHQTransactionsQuery,
  useHqWalletQuery,
} from "src/hq-admin/hq-api/hqTransactionApiSlice";
import { HeadCellTypes } from ".";

import { UpdateManagersDetails } from "./Components";
import { useUpdateAdminMutation } from "src/api/setttingsApislice";
import { SelectInput } from "src/components/SelectInput";
import {
  CATEGORIES,
  SOURCES,
  STATUS,
  TYPES,
  filterData,
} from "src/helpers/data";
import ProfileEditCard from "src/components/EditProfileCard";
import { Badge } from "src/components/Badge";
import { Tab, Tabs } from "@mui/material";
import { enUS } from "date-fns/locale";
import WithDrawal from "../Withdraw.tsx";
import { useUpdateBranchManagerMutation } from "src/api/manageBranchAPISlice";
import { useDebounce } from "src/hooks/useDebounce";

const BranchData: cardBtnType[] = [
  {
    id: 1,
    icon: User,
    name: "Branch Profile",
  },
  {
    id: 1,
    icon: User,
    name: "Manager's Profile",
  },

  {
    id: 3,
    icon: Attendant,
    name: "Attendant Profile",
  },
  {
    id: 4,
    icon: Rating,
    name: "Ratings and Reviews",
  },
  {
    id: 5,
    icon: Wallet,
    name: "Wallet Info",
  },
  {
    id: 5,
    icon: HqWallet,
    name: "Transactions",
  },
  {
    id: 6,
    icon: MainProduct,
    name: "Branch Products",
  },
  {
    id: 7,
    icon: OtherProduct,
    name: "Amenities",
  },
  {
    id: 8,
    icon: HqWallet,
    name: "Withdrawal Requests",
  },
];
const headCells: any[] = [
  {
    id: "created",
    minWidth: 220,
    label: "Transaction Date",
  },
  {
    id: "accountName",
    minWidth: 190,
    label: "Name",
  },
  {
    id: "amount",
    minWidth: 120,
    label: "Amount (N)",
  },
  {
    id: "type",
    minWidth: 90,
    label: "Type",
  },
  {
    id: "reference",
    minWidth: 170,
    label: "Reference",
  },
  {
    id: "category",
    minWidth: 210,
    label: "Category",
  },
  {
    id: "source",
    minWidth: 190,
    label: "Source",
  },
  {
    id: "status",
    minWidth: 100,
    label: "Status",
  },
];

export default function SingleBranch() {
  const [tabName, setTabName] = useState<string>("branch profile");
  const [showModal, setShowModal] = useState(false);
  const [showManageUpdate, setShowManageUpdates] = useState(false);
  const { slicedPath } = useCustomLocation();
  const branchResult = useFetchSingleHQBranchQuery(slicedPath[2]);
  const [page, setPage] = useState(1);
  const [info, setInfo] = useState({
    source: "",
  });
  // const results = useFetchFuelLevelQuery({});
  const [filteredValue, setFilteredValue] = useState<string>("");
  const { debouncedValue } = useDebounce(filteredValue, 700);

  const result = useFetchAttendentListQuery({
    stationBranch: slicedPath[2],
  });

  const pumpAttendant = useMemo(() => result?.data, [result]);

  const station = branchResult?.data?.station;

  const [updateBranchDetails, updateBranchDetailsResult] =
    useUpdateHqBranchDetailsMutation();
  const [updateBranchManagerDetailsMutation, updateBranchDetailsManagerResult] =
    useUpdateBranchManagerMutation();

  async function updateBranch(values: any) {
    try {
      const response = await updateBranchDetails({
        id: slicedPath[2],
        name: values.name,
        config: { pumpCount: values.pumpCount },
      }).unwrap();
      setTabName("branch profile");
      setShowModal(false);
      SuccessNotification("Successful");
      setShowModal(() => false);
    } catch (error: any) {
      setShowModal(() => false);
      handleNotification(error);
    }
  }

  async function updateBranchManagerDetails(values: FormType) {
    try {
      const response = await updateBranchManagerDetailsMutation({
        id: station?.manager?._id,
        ...values,
      }).unwrap();
      setTabName("manager's profile");
      SuccessNotification("Successful");
      setShowModal(() => false);
    } catch (error: any) {
      setShowModal(() => false);
      handleNotification(error);
    }
  }

  // HANDLE CHANGE FOR TABLE TAB
  const handleSelectChange = (event: { target: { value: string } }) => {
    setInfo((prev) => {
      return { source: event.target.value };
    });
  };

  const handleBranchEdit = (data: any) => {
    return {
      pumpCount: data.pumpCount,
      name: data.name,
    };
  };
  const handledAPIResponse = useMemo(() => {
    return {
      profileData: {
        name: station?.name,
        // systemCode: station?.systemCode,
        managerSystemCode: station?.manager?.systemCode,
        phoneNumber: station?.phoneNumber,
        state: station?.location?.state,
        lga: station?.location?.lga,
        address: station?.location?.address,
        latitude: station?.location?.latitude,
        longitude: station?.location?.longitude,
        openingTime: station?.config?.openTime?.from,
        closingTime: station?.config?.openTime?.to,
        pumpCount: station?.config?.pumpCount,
        walletNumber: station?.wallets?.walletNumber,
        // hqName: station?.stationHQ?.name,
        status: station?.status,
      },
      manager: {
        firstName: station?.manager?.firstName,
        lastName: station?.manager?.lastName,
        email: station?.manager?.email,
        phoneNumber: station?.manager?.phoneNumber,
      },
      imageUrl: station?.coverImage?.url,
      branchManager: station?.manager,
      pumpAttendants: station?.pumpAttendants,
      rating: station?.ratings,
      stationtanks: station?.stationtanks,
      account: station?.wallets?.availableBalance,
      offlineSalesBalance: station?.wallets?.offlineSalesBalance,
      config: station?.config,
      walletInfo: {
        walletName: station?.wallets?.walletName,
        walletNumber: station?.wallets?.walletNumber,
        bankName: station?.wallets?.bankName,
        bankReference: station?.wallets?.bankReferenceNumber,
      },
    };
  }, [station]);

  const tabDetails = tabName.toLowerCase();

  const allTransactionsResult = useGetAllHQTransactionsQuery({
    stationBranch: slicedPath[2],
    ...(debouncedValue && { search: debouncedValue }),
    limit: 8,
    orderBy: "createdAt:desc",
    populate: "stationBranch",
    page,
    ...(STATUS.includes(info?.source) && { status: info.source }),
    ...(SOURCES.includes(info?.source) && { source: info.source }),
    ...(CATEGORIES.includes(info?.source) && { category: info.source }),
    ...(TYPES.includes(info?.source) && { type: info.source }),
  });

  const handleChangePage = (e: any, newPage: number) => {
    setPage(newPage);
  };

  const handledTransactionResponse = useMemo(() => {
    const transactions = allTransactionsResult?.data || [];

    return transactions?.data?.reduce(
      (
        acc: { tableData: { previewData: any } }[],
        cur: TransactionsType | any
      ) => {
        const tableData: any = {
          created: format(new Date(cur.createdAt), "do MMM yyyy, hh:mm a", {
            locale: enUS,
          }),
          reference: cur?.reference || "----------",
          fee: CurrencyFormatter(cur.meta.fee),
          accountName: `${
            cur.source === "instant_wallet"
              ? cur?.meta?.destinationHolderName || "----------"
              : cur?.type === "credit"
              ? cur?.meta?.payerName || "----------"
              : cur?.type === "debit"
              ? cur?.meta?.senderName || "----------"
              : ""
          }`,
          amount: CurrencyFormatter(Number(cur?.amount)),
          category: cur?.category ? (
            <Badge
              type={"TRANSACTION_CATEGORY_OPTIONS"}
              status={cur?.category}
            />
          ) : (
            "----------"
          ),
          categoryType: cur?.category || cur?.source,
          source: cur?.source ? (
            <Badge type={"TRANSACTION_SOURCES_OPTIONS"} status={cur?.source} />
          ) : (
            "----------"
          ),
          type: cur?.type ? (
            <Badge type={"TRANSACTION_TYPE_OPTIONS"} status={cur?.type} />
          ) : (
            cur?.type || "----------"
          ),
          status: cur?.status ? (
            <Badge type={"TRANSACTION_STATUS_OPTIONS"} status={cur?.status} />
          ) : (
            cur?.status || "----------"
          ),
          previewData: {},
        };

        tableData.previewData = {
          created: tableData.created,
          reference: tableData.reference,
          accountName: tableData.accountName,
          amount: tableData.amount,
          fee: tableData.fee,
          category: tableData.category,
          categoryType: tableData.categoryType,
          source: tableData.source,
          type: tableData.type,
          status: tableData.status,
          message: cur?.meta?.message || "----------",
          purpose: truncateString(cur?.purpose, 50) || "----------",
          balanceAfterTransaction: cur?.balanceAfterTransaction
            ? CurrencyFormatter(cur?.balanceAfterTransaction)
            : "----------",
        };

        switch (cur.category || cur.source) {
          case "bank_transfer":
            tableData.previewData = {
              ...tableData.previewData,
              sendersName: cur?.meta?.senderName || "----------",
              sendersAccountNumber:
                cur?.meta?.senderAccountNumber || "----------",
              sendersBank: cur?.meta?.bankName || "----------",
              receiversName: cur?.meta?.recieverName || "----------",
              receiversSystemCode:
                cur?.meta?.receiverSystemCode || "----------",
              recieverWalletNumber:
                cur?.meta?.recieverWalletNumber || "----------",
              receiverWalletBank: cur?.meta?.receiverWalletBank || "----------",
            };
            break;
          case "instant_wallet":
            tableData.previewData = {
              ...tableData.previewData,
              sendersName: cur?.meta?.senderName || "----------",
              sendersSystemCode: cur?.meta?.senderSystemCode || "----------",
              receiversName: cur?.meta?.destinationHolderName || "----------",
              category: tableData.category,
              receiversSystemCode:
                cur?.meta?.receiverSystemCode || "----------",
              product: cur?.meta?.product || "----------",
              instantWalletCode:
                cur?.meta?.destinationWalletNumber || "----------",
              recieverWalletNumber:
                cur?.meta?.destinationWalletNumber || "----------",
            };
            break;
          case "withdrawal":
            tableData.previewData = {
              ...tableData.previewData,
              // sendersName: cur?.meta?.senderName || "----------",
              accountWalletNumber:
                cur?.meta?.senderWalletNumber || "----------",
              // receiversName: cur?.meta?.receiverName || "----------",
              //   cur?.meta?.receiverAccountNumber || "----------",
              // receiversBank: cur?.meta?.receiverBank || "----------",
            };
            break;
          case "referral_bonus":
            tableData.previewData = {
              ...tableData.previewData,
              accountName: cur?.meta?.destinationHolderName || "----------",
              accountSystemCode: cur?.meta?.accountSystemCode || "----------",
              nameOfPersonReferred:
                cur?.meta?.nameOfPersonReferred || "----------",
              systemCodeOfPersonReferred:
                cur?.meta?.systemCodeOfPersonReferred || "----------",
            };
            break;
          case "premium_feature":
            tableData.previewData = {
              ...tableData.previewData,
              branchName:
                cur?.meta?.branchName || cur.stationBranch.name || "----------",
              branchSystemCode:
                cur?.meta?.branchSystemCode ||
                cur.stationBranch.systemCode ||
                "----------",
              nameOfFeatureAdded: cur?.meta?.nameOfFeatureAdded || "----------",
            };
            break;
          default:
            break;
        }

        switch (cur.source) {
          case "available_balance":
            if (tableData.previewData.branchSystemCode) {
              delete tableData.previewData.branchSystemCode;
            }

            break;
        }
        return [...acc, { ...tableData }];
      },
      []
    );
  }, [allTransactionsResult]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // setValue(newValue);
  };
  return (
    <section>
      {/* <LoaderContainer /> */}
      <article className="w-full h-screen px-2">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4  py-3">
          <>
            {BranchData.map((dt) => (
              <Fragment>
                <CardButton
                  name={dt.name}
                  icon={dt.icon}
                  height={"60px"}
                  activeBtn={tabName}
                  onClick={() => setTabName(dt.name)}
                  // showCard={showCard}
                />
              </Fragment>
            ))}
          </>
        </div>

        <LoaderContainer
          data={
            updateBranchDetailsResult.isLoading
              ? updateBranchDetailsResult
              : branchResult
          }
        >
          {tabDetails === "branch profile" ? (
            <ProfileCard
              header={"branch profile"}
              data={handledAPIResponse.profileData || {}}
              //   onClick={() => setShowModal(true)}
              showImage={false}
              imageURL={handledAPIResponse?.imageUrl || ""}
              showBanner={true}
              showHeader={true}
              onClick={() => setTabName("edit branch")}
              buttonText={"Edit Branch"}
              showButton={true}
              flag={"station_branch"}
              flagText={
                handledAPIResponse.profileData.status === "available"
                  ? "Close Station"
                  : "Open Station"
              }
            />
          ) : null}

          {tabDetails === "manager's profile" ? (
            <ProfileCard
              data={handledAPIResponse.manager || {}}
              onClick={() => setShowManageUpdates(true)}
              showImage={false}
              otherData={handledAPIResponse.branchManager}
              showBanner={Boolean(handledAPIResponse?.imageUrl)}
              imageURL={handledAPIResponse?.imageUrl}
              showButton={true}
              showHeader={true}
              header={"manager's profile"}
              flag={"branch_manager"}
              flagText={
                handledAPIResponse.branchManager?.accountStatus.status ===
                "pending"
                  ? "Activate"
                  : handledAPIResponse.branchManager?.accountStatus.status ===
                      "available" ||
                    handledAPIResponse.branchManager?.accountStatus.status ===
                      "confirmed"
                  ? "Suspend"
                  : "Unsuspend"
              }
            />
          ) : null}
          {tabDetails === "attendant profile" ? <AttendantProfile /> : null}

          {tabDetails === "ratings and reviews" ? (
            <BranchReview ratings={handledAPIResponse.rating} />
          ) : null}
          {tabDetails === "wallet info" ? (
            <BranchAccountBalance
              account={handledAPIResponse.account}
              offlineSalesBalance={handledAPIResponse.offlineSalesBalance}
              info={handledAPIResponse}
            />
          ) : null}

          {tabDetails === "transactions" ? (
            <div>
              <div className="flex justify-between items-center mt-6 h-20">
                <SearchInput
                  name="branch-search"
                  placeholder="Search"
                  value={filteredValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const target = e.target;
                    setFilteredValue(target.value);
                  }}
                />
                <div className="w-fit flex gap-x-4 items-center mr-auto px-4 h-24">
                  <SelectInput
                    tabData={filterData}
                    filteredValue={info.source}
                    onChange={handleSelectChange}
                  />
                </div>
              </div>

              <Transaction
                headCells={headCells}
                transactions={handledTransactionResponse}
                transactionResult={allTransactionsResult}
                handleChangePage={handleChangePage}
              />
            </div>
          ) : null}
          {tabDetails === "branch products" ? (
            <ProductCard
              data={handledAPIResponse.stationtanks}
              products={handledAPIResponse.config.products}
              show
            />
          ) : null}
          {tabDetails === "amenities" ? (
            <AmenitiesCard data={handledAPIResponse.config?.amenities} />
          ) : null}
          {tabDetails === "withdrawal requests" ? <WithDrawal /> : null}
          {tabDetails === "edit branch" ? (
            <ProfileEditCard
              data={handleBranchEdit(handledAPIResponse.profileData) || {}}
              showImage={false}
              showSecondButton={true}
              showButton={true}
              //   status={status}
              secondOnClick={() => setTabName("branch profile")}
              secondButtonText={"Cancel"}
              //   fn={handleStatus}
              flag={true}
              makeRequest={updateBranch}
              //   handleModal={handleModal}
              apiResult={updateBranchDetailsResult}
              showModal={showModal} //"status": "suspended"
              suspend={
                handledAPIResponse?.profileData?.status === "pending"
                  ? "Activate"
                  : handledAPIResponse?.profileData?.status === "available"
                  ? "Suspend"
                  : "Unsuspend"
              }
            />
          ) : null}
        </LoaderContainer>
        {showModal ? (
          <FormModal
            name="Update branch details"
            onClick={() => setShowModal((prevState) => !prevState)}
          >
            <AddNewBranch
              makeApiRequest={(values: any) => updateBranch(values)}
              apiResult={updateBranchDetailsResult}
              initalValue={{
                name: station?.name,
                phoneNumber: station?.phoneNumber,
              }}
            />
          </FormModal>
        ) : null}

        {showManageUpdate ? (
          <FormModal
            name="Update branch manager's details"
            onClick={() => setShowManageUpdates((prevState) => !prevState)}
          >
            <UpdateManagersDetails
              makeApiRequest={(values: any) =>
                updateBranchManagerDetails(values)
              }
              apiResult={updateBranchDetailsManagerResult}
              initalValue={{
                firstName: station?.manager?.firstName,
                lastName: station?.manager?.lastName,
                // email: station?.manager?.email,
                // phoneNumber: station?.phoneNumber,
              }}
            />
          </FormModal>
        ) : null}
      </article>
    </section>
  );
}

function AmenitiesCard(props: any) {
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  function closeAddAmenitiesModal(): void {
    setShowAddModal((prev) => !prev);
  }

  return (
    <div className="w-full h-fit bg-white shadow-lg rounded-lg text-[14px] py-6 px-4 lg:px-16">
      <div className=" h-11 mr-6 flex w-full justify-end">
        <Button
          text="Create Amenities"
          type="button"
          showIcon={true}
          onClick={() => setShowAddModal(true)}
        />
      </div>
      {showAddModal ? (
        <FormModal
          name="Create Amenities"
          onClick={() => setShowAddModal((prevState) => !prevState)}
        >
          <AddAmenityForm close={closeAddAmenitiesModal} />{" "}
        </FormModal>
      ) : null}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-y-10 py-4 md:gap-x-2 text-start ">
        {Object.keys(props?.data)?.map((dt, i) => (
          <Fragment key={i + 1}>
            <div>
              <h2 className="text-black capitalize">
                {splitByUpperCase(props?.data[dt]?.name ?? null)}
              </h2>
              <span className="block bg-[#737587] h-0.5 w-20 my-1.5 rounded-lg capitalize"></span>
              {/* <h2 className="text-[#002E66] capitalize">
                {CurrencyFormatter(props?.data[dt]?.price ?? null)}
              </h2>
              <h2 className="text-[#002E66] capitalize">
                Available:
                {!props?.data[dt]?.isAvailable ? " NO" : " YES"}
              </h2> */}
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
}

const BranchAccountBalance = (props: {
  account: string;
  offlineSalesBalance: string;
  info: { [index: string]: string | any };
}) => {
  const { slicedPath } = useCustomLocation();
  const result = useBranchWalletQuery(slicedPath[2]);
  console.log(result);
  const [updateWalletPermissionMutation, updateWalletResult] =
    useUpdateWalletPermissionMutation();
  const [showStrictAccount, setStrictAccount] = useState(false);
  const [showLockAccount, setShowLockAccount] = useState(false);

  const walletRestrictionStatus = result?.data?.canWithdraw;
  const lockStatus = result?.data?.locked;

  function handleStrictWalletState() {
    setStrictAccount((prevState) => !prevState);
  }
  function handleLockedWalletState() {
    setShowLockAccount((prevState) => !prevState);
  }

  async function updateWalletPermission() {
    try {
      const response = await updateWalletPermissionMutation({
        id: slicedPath[2],
        canWithdraw: walletRestrictionStatus ? false : true,
      }).unwrap();
      handleStrictWalletState();

      SuccessNotification("Successful");
    } catch (error: any) {
      handleStrictWalletState();
      handleNotification(error);
    }
  }

  async function updatelockStatus() {
    try {
      const response = await updateWalletPermissionMutation({
        id: slicedPath[2],
        locked: lockStatus ? false : true,
      }).unwrap();
      handleLockedWalletState();
      SuccessNotification("Successful");
    } catch (error: any) {
      handleLockedWalletState();
      handleNotification(error);
    }
  }

  return (
    <TableLoader tableData={Object.keys(result?.data || {})} data={result}>
      <div className="p-4 basis-[60%] rounded-[10px] bg-white grid grid-cols-1 gap-x-10 gap-y-3 justify-items-center content-center mt-6 pl-6">
        <div className="flex gap-4">
          <div className="text-center text-[#002E66]">
            <h3 className="text-[14px]my-">Available Balance</h3>
            <h2 className="text-[24px] font-bold">
              {CurrencyFormatter(Number(props?.account))}
            </h2>
          </div>
          <div className="text-center text-[#002E66]">
            <h3 className="text-[14px]my-">Offline sales Balance</h3>
            <h2 className="text-[24px] font-bold">
              {CurrencyFormatter(Number(props?.offlineSalesBalance))}
            </h2>
          </div>
        </div>
        <div className="w-full  grid grid-cols-3 gap-y-6 mt-2">
          {/* {Object.keys(props?.info.walletInfo)?.map((dt, i) => { */}
          <div>
            <h2 className="text-black capitalize">wallet Name</h2>
            <h2 className="text-[#002E66] capitalize">
              {result?.data?.walletName || 0}
            </h2>
          </div>
          <div>
            <h2 className="text-black capitalize">Account Number</h2>
            <h2 className="text-[#002E66] capitalize">
              {result?.data?.walletNumber || 0}
            </h2>
          </div>
          <div>
            <h2 className="text-black capitalize">Bank name</h2>
            <h2 className="text-[#002E66] capitalize">
              {result?.data?.bankName || 0}
            </h2>
          </div>
        </div>
        <div className=" mx-auto w-full flex items-center justify-center mt-2 gap-x-6   px-4">
          <Button
            className="h-full w-full max-w-[350px] font-bold bg-[#D0D5DD] p-3 rounded-lg hover: text-[#002E66] flex items-center justify-center"
            text={
              walletRestrictionStatus
                ? "Activate Strict Mode"
                : "Activate Free Mode"
            }
            onClick={handleStrictWalletState}
            type="button"
          />

          {/* <Button
            className="h-full w-full font-bold bg-[#D0D5DD] p-3 rounded-lg hover: text-[#002E66] flex items-center justify-center"
            text={lockStatus ? "Unlock Account" : "Lock Account"}
            type="button"
            onClick={handleLockedWalletState}
          /> */}
        </div>

        {showStrictAccount ? (
          <Modal>
            <FlagModal
              info={`Are you sure you want ${
                walletRestrictionStatus
                  ? "Activate Strict Mode"
                  : "Activate Free Mode"
              } ?`}
              onClose={handleStrictWalletState}
              onConfirmation={updateWalletPermission}
              showModal={updateWalletResult?.isLoading}
            />
          </Modal>
        ) : null}
        {showLockAccount ? (
          <Modal>
            <FlagModal
              info={`Are you sure you want to ${
                lockStatus ? "Unlock Account" : "Lock Account"
              }?`}
              onClose={handleLockedWalletState}
              onConfirmation={updatelockStatus}
              showModal={updateWalletResult?.isLoading}
            />
          </Modal>
        ) : null}
      </div>
    </TableLoader>
  );
};

export const AddNewBranch = (props: any) => {
  const initValue = props.initalValue;

  const Formik = useFormik<UpdateFormType>({
    initialValues: {
      ...initValue,
    },
    validateOnBlur: true,
    validateOnChange: true,
    // validationSchema: AddbranchValidation[step],

    onSubmit: (values) => {
      props.makeApiRequest(values);
    },
  });

  const styles =
    "h-[38px] py-6 rounded-[38px] w-full border border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 px-4 text-[14px] bg-[#D9D9D9]";
  const labelStyles =
    "block mb-[6px] text-black text-start font-normal text-[14px] text-black ml-5 my-6";

  const FormData = [
    {
      id: "name",
      name: "Branch name",
      type: "text",
      styles: `${styles} ${
        Formik.errors.name && Formik.touched.name
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.name,
      onBlur: Formik.handleBlur,
      // disabled: addNewBranchResult?.isLoading,
      error: Formik.errors.name,
      touched: Formik.touched.name,
    },
    {
      id: "phoneNumber",
      name: "Branch contact info",
      type: "text",
      styles: `${styles} ${
        Formik.errors.phoneNumber && Formik.touched.phoneNumber
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.phoneNumber,
      onBlur: Formik.handleBlur,
      // disabled: addNewBranchResult?.isLoading,
      error: Formik.errors.phoneNumber,
      touched: Formik.touched.phoneNumber,
    },
  ];

  return (
    <form
      onSubmit={Formik.handleSubmit}
      className="w-full flex flex-col justify-center items-center px-4 h-full"
    >
      <div className="grid grid-cols-1 w-full gap-x-2 content-center">
        {FormData.slice(0, 6).map((dt, i) => (
          <FormInput
            id={dt.id}
            name={dt.name}
            type={dt.type}
            styles={dt.styles}
            labelStyles={dt.labelStyles}
            onChange={dt.onChange}
            value={dt.value}
            onBlur={dt.onBlur}
            // disabled={dt.disabled}
            error={dt.error}
            touched={dt.touched}
          />
        ))}
      </div>

      <div className="w-full">
        <Button
          text="Update Branch Details"
          // disabled={addNewBranchResult?.isLoading}
          showModal={props.apiResult?.isLoading}
          className="h-[41px] mt-6 font-bold text-white rounded-[38px] w-full hover: bg-[#002E66]"
          type="submit"
        />
      </div>
    </form>
  );
};
