import React from "react";

type InputTypes = {
  label: string;
  placeholder: string;
  type?: string;
};

export default function InputComp({
  label,
  placeholder,
  ...props
}: InputTypes) {
  return (
    <div>
      <label
        htmlFor=""
        className="block mb-[6px]  text-start font-normal text-[14px] text-black ml-0 my-2"
      >
        {label}
      </label>
      <input
        className="h-[38px] py-6 rounded-lg w-full border border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 px-4 text-[14px] bg-white"
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
}
