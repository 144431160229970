import React, { useMemo, useState } from "react";
import { Button } from "src/components/Button";
import useCustomLocation from "src/hooks/useCustomLocation";

import { LoaderContainer } from "src/components/LoaderContainer";

import {
	ErrorNotification,
	SuccessNotification,
} from "src/helpers/helperFunction";

import {
	useRegisterForDepotMutation,
	useSingleDepotQuery,
} from "src/api/manageBranchAPISlice";
import { FormModal } from "src/components/ModalComp";
import RequestProduct from "src/components/RequestProduct";
import SingleListComp from "src/components/SingleListComp";
import { FaFileAlt } from "react-icons/fa";
import { MdMarkEmailUnread } from "react-icons/md";
import { FaSquarePhone } from "react-icons/fa6";

import LocationOnTwoToneIcon from "@mui/icons-material/LocationOnTwoTone";
import { useAuth, useRegisteredDepot } from "src/hooks/useAuth";
import DepotProducts from "./DepotProducts";

// TAB DATA FOR TABLE TAB
// const tabData: { id: number; value: string; label: string }[] = [
// 	{ id: 1, value: "one", label: "Depot Products" },
// ];

export default function SingleMarketPlaceDepot() {
	// const { listOfRegisteredDepot } = useRegisteredDepot();
	const { slicedPath } = useCustomLocation();

	const [showModal, setShowModal] = useState(false);
	const [showRegistrationModal, setRegistrationModal] = useState(false);
	const { user } = useAuth();

	const getSingleDepotQuery = useSingleDepotQuery(slicedPath[2]);

	const [RegisterDepotMutation, registerDepotResult] =
		useRegisterForDepotMutation();

	const depotDetails = useMemo(
		() => getSingleDepotQuery?.data?.depot[0],
		[getSingleDepotQuery]
	);

	function handleDepotProductRequestModal() {
		setShowModal((prevState) => !prevState);
	}
	function handleDepotRegistrationModal() {
		setRegistrationModal((prevState) => !prevState);
	}

	async function handleRegisterDeport(id: string) {
		try {
			await RegisterDepotMutation({ depot: id }).unwrap();

			SuccessNotification("Registered Successfully");
			setRegistrationModal(false);
		} catch (error: any) {
			ErrorNotification(error?.data?.message);
			setRegistrationModal(false);
		}
	}

	const isRegistered = useMemo(
		() => user?.subscribedDepots.includes(slicedPath[2]),
		[user, slicedPath]
	);

	return (
		<section>
			<LoaderContainer data={getSingleDepotQuery}>
				<article>
					<div className="flex flex-col items-center gap-y-6">
						<div>
							<img
								src={depotDetails?.logo?.url}
								className="h-[97px] w-[97px]  rounded-full object-cover"
								alt=""
							/>
						</div>

						<div className="text-2xl ">
							<h6 className="font-bold text-black capitalize mt-3">
								{depotDetails?.name}
							</h6>
						</div>
						<div className=" ">
							{isRegistered ? (
								<Button
									className=" bg-primary text-white px-5 py-3 text-sm font-semibold  rounded-3xl"
									text="Register"
									type="button"
									onClick={handleDepotRegistrationModal}
									// onClick={() => handleRegisterDeport(slicedPath[2])}
								/>
							) : (
								<Button
									className=" bg-primary text-white px-5 py-3 text-sm font-semibold  rounded-3xl"
									text="Order Products"
									type="button"
									onClick={handleDepotProductRequestModal}
								/>
							)}
						</div>
						<div className="mx-auto">
							<div className="grid grid-cols-3 lg:grid-cols-4  xl:grid-cols-4 gap-4 justify-content-center py-5">
								<SingleListComp
									icon={<FaFileAlt className="text-blue-500 text-base" />}
									name="Products"
									value={depotDetails?.totalProducts}
								/>
								<SingleListComp
									icon={
										<MdMarkEmailUnread className="text-blue-900 text-base" />
									}
									name="Email"
									value={depotDetails?.email}
								/>
								<SingleListComp
									icon={<FaSquarePhone className="text-yellow-500 text-base" />}
									name="Phone Number"
									value={depotDetails?.phoneNumber}
								/>

								<SingleListComp
									icon={
										<LocationOnTwoToneIcon className="text-green-600 text-base" />
									}
									name="Location"
									value={depotDetails?.address}
								/>
							</div>
						</div>
					</div>

					<DepotProducts products={depotDetails?.products} />
				</article>
			</LoaderContainer>
			{showModal ? (
				<FormModal
					name="Order Products"
					onClick={handleDepotProductRequestModal}>
					<RequestProduct onClose={handleDepotProductRequestModal} />
				</FormModal>
			) : null}
			{showRegistrationModal ? (
				<FormModal name="Register" onClick={handleDepotRegistrationModal}>
					<div className="flex flex-col items-center justify-center mt-4 px-4 py-2 gap-y-4">
						<h2 className="text-center">
							Do you want to proceed with the registeration ? <br /> Certain
							registeration fee will be deducted from your wallet.
						</h2>
						<Button
							className=" bg-primary text-white px-5 py-2 mt-4 text-sm font-semibold  md:w-[150px] rounded-3xl"
							text="Confirm"
							type="button"
							showModal={registerDepotResult.isLoading}
							onClick={() => handleRegisterDeport(slicedPath[2])}
						/>
					</div>
				</FormModal>
			) : null}
		</section>
	);
}
