import React, { Fragment, useMemo, useState } from "react";
import { cardBtnType } from "src/helpers/alias";
import AdminProfile from "src/assets/img/AdminProfile.svg";
import ResetPasword from "src/assets/img/ResetPasword.svg";
import { CardButton } from "src/components/Card";
import ProfileCard from "src/components/ProfileCard";

import { FormModal } from "src/components/ModalComp";
import { useGetHQDetailsQuery } from "src/hq-admin/hq-api/hqAuthSlice";
import { LoaderContainer } from "src/components/LoaderContainer";
import { ResetAdminInfo } from "./ResetAdminInfo";
import { ResetPassword } from "./ResetPassword";
import { ResetHQInfo } from "./ResetHQAdmin";
import * as Yup from "yup";
import { FormikValues, useFormik } from "formik";
import { FormInput, PasswordInput } from "src/components/inputs";
import { Button } from "src/components/Button";
import { useUpdateTransactionPinMutation } from "src/hq-admin/hq-api/hqTransactionApiSlice";
import { toast } from "react-toastify";
import {
  ErrorNotification,
  SuccessNotification,
} from "src/helpers/helperFunction";

export default function HqSetting() {
  const hqDetailsResult = useGetHQDetailsQuery({});
  const user = useMemo(() => hqDetailsResult?.data?.data, [hqDetailsResult]);
  const [showPinModal, setShowPinModal] = useState<boolean>(false);

  const [cardName, setName] = useState<string>("HQ Details");
  const [showResetModal, setShowResetModal] = useState(false);
  const [showUpdateAdminModal, setShowUpdateAdminModal] = useState(false);

  const HQData: cardBtnType[] = [
    {
      id: 0,
      icon: AdminProfile,
      name: "HQ Details",
    },
    {
      id: 1,
      icon: AdminProfile,
      name: "Admin Profile",
    },

    {
      id: 4,
      icon: ResetPasword,
      name: "Update Password",
    },
    {
      id: 5,
      icon: ResetPasword,
      name: "Update pin",
      // link: APP_ROUTE.RESET_PASSWORD,
    },
  ];

  function handleCloseResetModal() {
    setShowResetModal((prevState) => !prevState);
  }
  const handledAPIResponse = useMemo(() => {
    return {
      profile: {
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        role: user?.role,
        phoneNumber: user?.phoneNumber,
      },
      station: {
        email: user?.stationHQ?.email,
        name: user?.stationHQ?.name,
        phoneNumber: user?.stationHQ?.phoneNumber,
        address: user?.stationHQ?.hqAddress,
        state: user?.stationHQ?.state,
        systemCode: user?.stationHQ?.systemCode,
      },
      avatar: user?.stationHQ?.logo?.url,
      id: user?.stationHQ?.id,
      hdAdminId: user?.id,
    };
  }, [user]);

  return (
    <section>
      <LoaderContainer data={hqDetailsResult}>
        <article>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4  py-3">
            <>
              {HQData.map((dt) => (
                <Fragment>
                  <CardButton
                    name={dt.name}
                    icon={dt.icon}
                    link={dt.link}
                    activeBtn={cardName}
                    height={"60px"}
                    onClick={() => {
                      dt?.name.toLowerCase() === "update pin"
                        ? setShowPinModal(true)
                        : setName(dt.name);
                    }}
                  />
                </Fragment>
              ))}
            </>
          </div>

          {cardName.toLowerCase().includes("hq") ? (
            <ProfileCard
              showBanner={true}
              data={handledAPIResponse?.station || {}}
              showImage={false}
              logoBranch="HQ"
              imageURL={handledAPIResponse.avatar}
              showButton={true}
              onClick={handleCloseResetModal}
            />
          ) : null}
          {cardName.toLowerCase() === "admin profile" ? (
            <ProfileCard
              showBanner={false}
              data={handledAPIResponse?.profile || {}}
              showImage={false}
              showButton={true}
              onClick={() => setShowUpdateAdminModal(true)}
              // imageURL={handledAPIResponse.avatar}
            />
          ) : null}
          {showUpdateAdminModal ? (
            <FormModal
              name="Update Profile"
              onClick={() => setShowUpdateAdminModal(false)}
            >
              <ResetAdminInfo
                data={handledAPIResponse}
                close={() => setShowUpdateAdminModal(false)}
              />
            </FormModal>
          ) : null}
          {cardName.toLowerCase() === "update password" ? (
            <FormModal
              name="Update Password"
              onClick={() => setName("admin profile")}
            >
              <ResetPassword
                data={handledAPIResponse}
                close={() => setName("admin profile")}
              />
            </FormModal>
          ) : null}

          {cardName.toLowerCase() === "update pin" ? (
            <SetTransactionPin
              close={() => setShowPinModal(false)}
              //   data={handledAPIResponse}
            />
          ) : null}
        </article>
      </LoaderContainer>
      {showResetModal ? (
        <FormModal name="Update Hq details" onClick={handleCloseResetModal}>
          <ResetHQInfo
            close={handleCloseResetModal}
            data={handledAPIResponse}
          />
        </FormModal>
      ) : null}
      {showPinModal ? (
        <FormModal
          name="Update Transaction pin"
          onClick={() => setShowPinModal(false)}
        >
          <SetTransactionPin
            close={() => setShowPinModal(false)}
            //   data={handledAPIResponse}
          />
        </FormModal>
      ) : null}
    </section>
  );
}
const SetTransactionPin = (props: { close: () => void }) => {
  const validationSchema = Yup.object().shape({
    accountPassword: Yup.string().required("Password is required"),
    pin: Yup.string()
      .required("PIN is required")
      .matches(/^\d{4}$/, "PIN must be exactly 4 digits"),
    confirmPin: Yup.string()
      .oneOf([Yup.ref("pin"), undefined], "PINs must match")
      .required("Confirm PIN is required"),
  });

  const [updateTransactionPin, updateTransactionPinDetails] =
    useUpdateTransactionPinMutation();

  const formik = useFormik<FormikValues>({
    initialValues: {
      accountPassword: "",
      pin: "",
      confirmPin: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const res: any = await updateTransactionPin(values);
        if (res?.data?.status === "success") {
          SuccessNotification("Pin Updated");
          return;
        } else if (res?.error.data?.status === "error") {
          ErrorNotification(`${res?.error?.data?.message}`);
        } else {
          ErrorNotification("Invalid Password");
        }
      } catch (error: any) {
        ErrorNotification(error.message);
      } finally {
        props.close();
      }
    },
  });

  const styles =
    "h-[38px] py-6 rounded-[38px] w-full border border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 px-4 text-[14px] ";
  const labelStyles =
    "block mb-[6px] text-black text-start font-normal text-[14px] text-black ml-0 my-2";

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="w-full flex flex-col justify-center items-center px-4 h-full overflow-y-auto"
    >
      <div className="grid grid-cols-1 w-full gap-x-2 content-center">
        <PasswordInput
          width="w-full"
          id="accountPassword"
          name="Enter Password"
          type="password"
          styles={`${styles} ${
            formik.errors.accountPassword && formik.touched.accountPassword
              ? "border-red-500"
              : "border-gray-300"
          }`}
          labelStyles={labelStyles}
          onChange={formik.handleChange}
          value={formik.values.accountPassword}
          onBlur={formik.handleBlur}
          disabled={formik.isSubmitting}
          error={formik.errors.accountPassword as string | undefined}
          touched={formik.touched.accountPassword as boolean | undefined}
        />
        <PasswordInput
          width="w-full"
          id="pin"
          name="Enter PIN"
          type="password"
          styles={`${styles} ${
            formik.errors.pin && formik.touched.pin
              ? "border-red-500"
              : "border-gray-300"
          }`}
          labelStyles={labelStyles}
          onChange={formik.handleChange}
          value={formik.values.pin}
          onBlur={formik.handleBlur}
          disabled={formik.isSubmitting}
          error={formik.errors.pin as string | undefined}
          touched={formik.touched.pin as boolean | undefined}
        />
        <PasswordInput
          width="w-full"
          id="confirmPin"
          name="Confirm PIN"
          type="password"
          styles={`${styles} ${
            formik.errors.confirmPin && formik.touched.confirmPin
              ? "border-red-500"
              : "border-gray-300"
          }`}
          labelStyles={labelStyles}
          onChange={formik.handleChange}
          value={formik.values.confirmPin}
          onBlur={formik.handleBlur}
          disabled={formik.isSubmitting}
          error={formik.errors.confirmPin as string | undefined}
          touched={formik.touched.confirmPin as boolean | undefined}
        />
      </div>
      <Button
        showModal={updateTransactionPinDetails.isLoading}
        text="update pin"
        disabled={updateTransactionPinDetails.isLoading || formik.isSubmitting}
        className="h-11 font-bold text-white mt-6 rounded-lg px-5 bg-[#002E66] flex items-center flex-row flex-nowrap justify-center w-full"
        type="submit"
      />
    </form>
  );
};
