import { CircularProgress } from "@mui/material";
import { AddIcon } from "./Icons";

type buttonTypes = {
  text: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  className?: string;
  type: "button" | "submit" | "reset" | undefined;
  showModal?: boolean | undefined;
  showIcon?: boolean | undefined;
};

export const Button = ({
  text,
  onClick,
  disabled,
  className = "h-11 gap-3 font-bold text-white rounded-lg px-5 bg-[#002E66]  w-auto flex items-center flex-row flex-nowrap justify-center",
  type,
  showModal,
  showIcon,
}: buttonTypes) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={className}
      type={type}
    >
      {showIcon ? (
        <div className="">
          <AddIcon />
        </div>
      ) : null}
      {!showModal ? (
        <div className=" min-w-[120px] flex justify-center items-center w-full">
          {text}
        </div>
      ) : (
        <CircularProgress size={20} className="mr-4" sx={{ color: "white" }} />
      )}
    </button>
  );
};
