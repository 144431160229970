import React, { useMemo, useState } from "react";
import { TableLoader } from "src/components/LoaderContainer";
import EnhancedTable from "src/components/Table";
import { forEnums, handleDateFormat } from "src/helpers/helperFunction";
import { useAuth } from "src/hooks/useAuth";
import useHandleRowClick from "src/hooks/useHandleRowClick";
import useHandleSelectAllClick from "src/hooks/useHandleSelectAllClick";
import useHandleSingleSelect from "src/hooks/useHandleSingleSelect";
import useIsSelected from "src/hooks/useIsSelected";
import { useFetchAllBranchesNotificationQuery } from "src/hq-admin/hq-api/notificationApiSlice";
import { NotificationPreview } from "./AutoComplete";

const headCells: readonly any[] = [
  {
    id: "title",
    minWidth: 170,
    label: "Title",
  },
  {
    id: "message",
    minWidth: 170,
    label: "Message",
  },
  {
    id: "for",
    minWidth: 170,
    label: "For",
  },
  {
    id: "createdAt",
    minWidth: 170,
    label: "Date",
  },
];

export default function BranchesNotification({
  notificationResult,
  handledAPIResponse,
  setPagination,
}: any) {
  const [showModal, setShowModal] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const { user } = useAuth();
  const { handleSelectAllClick, selected, setSelected } =
    useHandleSelectAllClick([]);
  const { isSelected } = useIsSelected(selected);
  const { handleClick } = useHandleSingleSelect(selected, setSelected);
  const { handleRowClick } = useHandleRowClick(fn);

  function fn(data: any) {
    setShowModal((prevState) => !prevState);

    setPreviewData(() => data);
  }

  let dataToChildren: any = {
    rows: handledAPIResponse || [],
    headCells,
    handleRowClick,
    showFlag: false,
    showCheckBox: false,
    isSelected,
    handleClick,
    handleSelectAllClick,
    selected,
    handleChangePage: (e: any, p: any) => setPagination(p),
    paginationData: {
      totalPage: notificationResult?.currentData?.data.totalPages,
      limit: notificationResult?.currentData?.data.limit,
      page: notificationResult?.currentData?.data.page,
    },
  };
  return (
    <section>
      <article>
        <TableLoader
          data={notificationResult || {}}
          tableData={handledAPIResponse || []}
        >
          <div className="h-full w-full">
            <div className="relative">
              <EnhancedTable {...dataToChildren} />
            </div>
          </div>
        </TableLoader>
      </article>

      {showModal ? (
        <NotificationPreview data={previewData} setShowModal={setShowModal} />
      ) : null}
    </section>
  );
}
