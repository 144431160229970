import React from "react";

export default function FundWalletModal({ data }: any) {
	return (
		<div>
			<div className="px-6 my-3 py-3 bg-green-200 text-green-600 ">
				<p className="text-sm">Fund wallet with this account information</p>
			</div>
			<div className="px-4 ml-3 flex flex-col gap-y-3">
				<div>
					<h3 className="text-sm">Account Name</h3>
					<h6>{data?.walletName}</h6>
				</div>
				<div>
					<h3 className="text-sm">Account Number</h3>
					<h6>{data?.walletNumber}</h6>
				</div>
				<div>
					<h3 className="text-sm">Bank Name</h3>
					<h6>{data?.bankName}</h6>
				</div>
			</div>
		</div>
	);
}
