import { useFormik } from "formik";
import { ChangeEvent, useEffect } from "react";
import { Button } from "src/components/Button";
import { Upload } from "src/components/Upload";
import { FormInput } from "src/components/inputs";
import {
  UpdateHQAdminInfoValidation,
  UpdateHQValidation,
} from "src/helpers/YupValidation";
import { UpdateHQAdminType, UpdateHQType } from "src/helpers/alias";
import {
  SuccessNotification,
  convert2base64,
  handleNotification,
} from "src/helpers/helperFunction";
import { useUpdateHqDetailsMutation } from "src/hq-admin/hq-api/settingsApiSlice";
import Image from "src/components/Image";
import { IMAGE_COMPRESS_OPTIONS } from "src/helpers/Constant";
import imageCompression from "browser-image-compression";

export const ResetHQInfo = (props: { close: () => void; data: any }) => {
  const { address, systemCode, ...rest } = props.data.station;
  const [updateAdmin, addNewResult] = useUpdateHqDetailsMutation();

  async function addNewAdmin(values: UpdateHQType) {
    try {
      const response = await updateAdmin(values).unwrap();

      SuccessNotification(response?.status);
    } catch (error: any) {
      handleNotification(error);
    }
    props.close();
  }

  const Formik = useFormik<UpdateHQType>({
    initialValues: {
      id: props.data?.id,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: UpdateHQValidation,
    onSubmit: (values: UpdateHQType) => {
      addNewAdmin(values);
    },
  });
  const styles =
    "h-[38px] py-6 rounded-[38px] w-full border border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 px-4 text-[14px] bg-[#D9D9D9]";
  const labelStyles =
    "block mb-[6px] text-black text-start font-normal text-[14px] text-black ml-0 my-2";

  const FormData = [
    {
      id: "name",
      name: "Hq name",
      type: "text",
      styles: `${styles} ${
        Formik.errors.name && Formik.touched.name
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.name,
      onBlur: Formik.handleBlur,
      disabled: addNewResult?.isLoading,
      error: Formik.errors.name,
      touched: Formik.touched.name,
    },
    {
      id: "email",
      name: "Admin's email",
      type: "email",
      styles: `${styles} ${
        Formik.errors.email && Formik.touched.email
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.email,
      onBlur: Formik.handleBlur,

      disabled: addNewResult.isLoading,
      error: Formik.errors.email,
      touched: Formik.touched.email,
    },
    {
      id: "phoneNumber",
      name: "Admin's phone number",
      type: "text",
      styles: `${styles} ${
        Formik.errors.phoneNumber && Formik.touched.phoneNumber
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.phoneNumber,
      onBlur: Formik.handleBlur,
      disabled: addNewResult?.isLoading,
      error: Formik.errors.phoneNumber,
      touched: Formik.touched.phoneNumber,
    },
  ];

  // HANDLE IMAGE UPLOAD TO BASE 64
  async function uploadAvatar(e: { [index: string]: string | any }) {
    const compressedFile = await imageCompression(
      e.target.files[0],
      IMAGE_COMPRESS_OPTIONS
    );
    Formik.setFieldValue("logo", await convert2base64(compressedFile));
  }

  // Populate input with previous data
  useEffect(() => {
    if (!props.data) {
      return;
    }

    Formik.setValues({
      ...rest,
      logo: props.data.avatar,
      id: props.data.id,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <form
      onSubmit={Formik.handleSubmit}
      className="w-full flex flex-col justify-center items-center px-4 h-full overflow-y-auto"
    >
      <div className="grid grid-cols-1 w-full gap-x-2 content-center">
        {FormData.slice(0, 4).map((_v, i) => (
          <FormInput
            width="w-full"
            id={_v.id}
            name={_v.name}
            type={"text"}
            styles={_v.styles}
            labelStyles={_v.labelStyles}
            onChange={_v.onChange}
            value={_v.value}
            onBlur={_v.onBlur}
            disabled={_v.disabled}
            // error={_v.error}
            // touched={_v.touched}
          />
        ))}
      </div>

      <div className="grid grid-cols-1 w-full gap-x-2 content-center pt-4">
        {Formik.values.logo ? (
          <div>
            <Image
              image={Formik.values.logo || ""}
              width={100}
              height={100}
              styles="mx-auto object-fit"
            />
          </div>
        ) : null}

        <div className="w-full h-24 mt-4">
          <Upload
            text="Upload profile image"
            name="logo"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              uploadAvatar(e);
            }}
          />
        </div>
      </div>

      <Button
        text="Submit"
        disabled={addNewResult?.isLoading}
        showModal={addNewResult?.isLoading}
        className="h-11 font-bold text-white mt-6 rounded-lg px-5 bg-[#002E66] flex items-center flex-row flex-nowrap justify-center w-full"
        type="submit"
      />
    </form>
  );
};
