import { InvalidateTag, providesTagList } from "src/helpers/helperFunction";
import { API_ROUTE, RTKTAG } from "src/helpers/Constant";
import { apiSlice } from "./apiSlice";

export const statesAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllStates: builder.query({
      query: (): any => `${API_ROUTE.STATES}?limit=7&orderBy=createdAt:desc`,
      providesTags: (result) =>
        providesTagList(result?.data?.data, RTKTAG.STATES) as any,
    }),
  }),
});

export const { useFetchAllStatesQuery } = statesAPISlice;
