import { API_ROUTE, HQ_API_ENPOINTS, RTKTAG } from "src/helpers/Constant";
import { apiSlice } from "./apiSlice";
import { providesTagList } from "src/helpers/helperFunction";
export const manageBranAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllBranch: builder.query({
      query: (params) =>
        ({
          url: `${API_ROUTE.GET_ALL_BRANCH}`,
          params,
        } as any),

      providesTags: [{ type: RTKTAG.MANAGER_BRANCH }],
    }),
    exportAllBranch: builder.query({
      query: (params) => API_ROUTE.GET_ALL_BRANCH,
      providesTags: [{ type: RTKTAG.MANAGER_BRANCH }],
    }),
    fetchBranch: builder.query({
      query: (id) => `${API_ROUTE.FETCH_BRANCH}/${id}`,
      providesTags: [{ type: RTKTAG.MANAGER_BRANCH }] as any,
    }),
    addNewBranch: builder.mutation({
      query: (body): any => ({
        url: API_ROUTE.ADD_NEW_BRANCH,
        method: "POST",
        body,
      }),
      invalidatesTags: (result) =>
        providesTagList(result?.hqProfile?.data, RTKTAG.MANAGER_BRANCH) as any,
    }),
    addNewHQBranch: builder.mutation({
      query: (body): any => ({
        url: API_ROUTE.ADD_HQ_BRANCH,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: RTKTAG.MANAGER_BRANCH }],
    }),

    changeStatus: builder.mutation({
      query: ({ id, ...body }): any => ({
        url: `${HQ_API_ENPOINTS.SINGLE_BRANCH}/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [
        { type: RTKTAG.MANAGER_BRANCH },
        { type: RTKTAG.HQ_BRANCH },
      ] as any,
    }),
    getDepotList: builder.query({
      query: (params): any => ({
        url: `${HQ_API_ENPOINTS.DEPOT}`,
        params,
      }),

      providesTags: [{ type: RTKTAG.DEPOT }] as any,
    }),
    getMyDepotList: builder.query({
      query: (params): any => ({
        url: `${HQ_API_ENPOINTS.MY_DEPOT}`,
        params,
      }),

      providesTags: [{ type: RTKTAG.DEPOT }] as any,
    }),
    singleDepot: builder.query({
      query: (id): any => `${API_ROUTE.DEPOT}/${id}`,

      providesTags: [{ type: RTKTAG.DEPOT }] as any,
    }),

    requestDepotProduct: builder.mutation({
      query: (body): any => ({
        url: `${API_ROUTE.DEPOT_ORDER}`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: RTKTAG.DEPOT }] as any,
    }),
    depotRequestList: builder.query({
      query: (params): any => ({
        url: `${API_ROUTE.DEPOT_ORDER}`,
        params,
      }),

      providesTags: [{ type: RTKTAG.DEPOT }] as any,
    }),
    depotInvoiceList: builder.query({
      query: (params): any => ({
        url: API_ROUTE.DEPOT_INVOICE_LIST,
        params,
      }),

      providesTags: [{ type: RTKTAG.DEPOT }, { type: RTKTAG.INVOICE }] as any,
    }),
    fetchSingleInvoice: builder.query({
      query: ({ id, ...params }): any => ({
        url: `${API_ROUTE.DEPOT_INVOICE_SINGLE}/${id}`,
        params,
      }),

      providesTags: [{ type: RTKTAG.DEPOT }, { type: RTKTAG.INVOICE }] as any,
    }),
    singleProductList: builder.query({
      query: ({ id }): any => ({
        url: `${API_ROUTE.DEPOT_ORDER_SINGLE}/${id}`,
      }),

      providesTags: [{ type: RTKTAG.DEPOT }] as any,
    }),
    requestSummary: builder.query({
      query: (id): any => ({
        url: HQ_API_ENPOINTS.DEPOT_SUMMARY,
      }),

      providesTags: [{ type: RTKTAG.DEPOT }] as any,
    }),
    depotProducts: builder.query({
      query: (params): any => ({
        url: HQ_API_ENPOINTS.DEPOT_PRODUCTS,
        params,
      }),

      providesTags: [{ type: RTKTAG.DEPOT }] as any,
    }),
    volumeReaction: builder.mutation({
      query: ({ id, ...body }): any => ({
        url: `${API_ROUTE.DEPOT_ORDER}/${id}/volume`,
        method: "PATCH",
        body,
      }),

      invalidatesTags: [{ type: RTKTAG.DEPOT }] as any,
    }),
    payDepot: builder.mutation({
      query: (body): any => ({
        url: `${API_ROUTE.DEPOT_ORDER_SINGLE}/invoice/pay`,
        method: "POST",
        body,
      }),

      invalidatesTags: [{ type: RTKTAG.DEPOT }] as any,
    }),
    deleteInvoice: builder.mutation({
      query: (id): any => ({
        url: `${API_ROUTE.DEPOT_INVOICE_SINGLE}/${id}`,
        method: "DELETE",
      }),

      invalidatesTags: [{ type: RTKTAG.DEPOT }] as any,
    }),
    registerForDepot: builder.mutation({
      query: (body): any => ({
        url: HQ_API_ENPOINTS.DEPOT_REGISTRATION,
        method: "POST",
        body,
      }),

      invalidatesTags: [{ type: RTKTAG.DEPOT }] as any,
    }),
    updateBranchManager: builder.mutation({
      query: ({ id, ...body }: any): string | any => ({
        url: `${API_ROUTE.UPDATE_STATION_USER}/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [
        { type: RTKTAG.MANAGER_BRANCH },
        { type: RTKTAG.HQ_BRANCH },
      ] as any,
    }),
  }),
});
export const {
  useFetchAllBranchQuery,
  useAddNewBranchMutation,
  useFetchBranchQuery,
  useAddNewHQBranchMutation,
  useExportAllBranchQuery,
  useUpdateBranchManagerMutation,
  useChangeStatusMutation,
  useGetDepotListQuery,
  useSingleDepotQuery,
  useRequestDepotProductMutation,
  useDepotRequestListQuery,
  useDepotInvoiceListQuery,
  useSingleProductListQuery,
  useVolumeReactionMutation,
  usePayDepotMutation,
  useDeleteInvoiceMutation,
  useRequestSummaryQuery,
  useRegisterForDepotMutation,
  useGetMyDepotListQuery,
  useDepotProductsQuery,
  useFetchSingleInvoiceQuery,
} = manageBranAPISlice;
