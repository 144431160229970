import React, { forwardRef } from "react";
import Logo from "src/assets/img/Logo.jpeg";
import {
  CurrencyFormatter,
  splitByUpperCase,
} from "src/helpers/helperFunction";

const DownloadableReceipt = React.forwardRef((props: any, ref) => {
  const downloadedData = {
    ...(props?.data?.created && { transactionDate: props?.data?.created }),
    ...(props?.data?.transactionDate && {
      transactionDate: props?.data?.transactionDate,
    }),

    reference: props?.data?.reference,
    amount: props?.data?.amount,
    fee: props?.data?.fee,
    ...(props?.data?.branchName && { branch: props?.data?.branchName }),
    ...(props?.data?.accountName && { accountName: props?.data?.accountName }),
    ...(props?.data?.stationHQ && { stationHQ: props?.data?.stationHQ }),
    ...(props?.data?.receiversName &&
      props?.data?.receiversName !== "----------" && {
        receiversName: props?.data?.receiversName,
      }),
    ...(props?.data?.receiversAccountNumber &&
      props?.data?.receiversAccountNumber !== "----------" && {
        receiversAccountNumber: props?.data?.receiversAccountNumber,
      }),
    ...(props?.data?.sendersName && { sendersName: props?.data?.sendersName }),
    ...(props?.data?.payerName && { payerName: props?.data?.sendersName }),
    ...(props?.data?.senderWalletNumber && {
      senderWalletNumber: props?.data?.senderWalletNumber,
    }),
    ...(props?.data?.accountWalletNumber && {
      accountWalletNumber: props?.data?.accountWalletNumber,
    }),
    reason: props?.data?.message,
    category: props?.data?.category,
    status: props?.data?.status,
  };
  const headers = Object.keys(downloadedData || {});
  const content = Object.values(downloadedData || {}) as any;

  return (
    <div
      ref={ref as any}
      className="max-w-2xl mx-auto bg-white h-full  rounded-lg  p-6"
    >
      <div className="text-center mb-6">
        <img src={Logo} alt="Logo" className="mx-auto h-48 w-48 mb-2" />
        <h2 className="text-xl font-bold text-grey-800 dark:text-grey-200">
          Aptfuel Transaction Receipt
        </h2>
      </div>
      {headers?.map((item, i) => (
        <div
          key={i + 1}
          className="grid grid-cols-2 border-b border-[#e3e3e3]  mx-auto gap-6 mb-4 "
        >
          <p className="text-sm text-start capitalize text-grey-600 dark:text-grey-400">
            {splitByUpperCase(item)}
          </p>
          <p className=" text-grey-800 dark:text-grey-200 text-right capitalize">
            {content[i]}
          </p>
        </div>
      ))}
      <div className="text-center mb-6 flex flex-col items-center justify-end h-56 ">
        <p className="font-bold">Total Amount</p>
        <div className="inline-block bg-primary text-white py-2 px-4 rounded-lg mt-2 mb-4">
          <p className="font-bold">
            {CurrencyFormatter(
              parseFloat(`${downloadedData?.amount}`?.split("NGN")[1]) +
                parseFloat(`${downloadedData?.fee}`?.split("NGN")[1])
            )}
          </p>
        </div>
        <p className="text-xs text-grey-600 dark:text-grey-400 text-center">
          Disclaimer: If your transaction is successful, the beneficiary's
          account will be credited. Please note that all transactions are
          subject to potential interruptions due to the nature of the internet,
          including delayed transmission, transmission blackout, and incorrect
          data transmission.
        </p>
      </div>
    </div>
  );
});

export default DownloadableReceipt;
