import React from "react";

export default function SummaryCard(props: any) {
  const { name, amount, icon, isDepot = false } = props;
  return (
    <div className=" rounded-lg bg-white py-2 px-2 flex items-center  shadow-lg">
      <div className="flex items-center ">
        {isDepot ? (
          <img src={icon} width={50} height={50} className="mr-4" alt="" />
        ) : (
          icon
        )}
      </div>
      <div>
        <p className="text-[13px] font-[600] leading-[21px] text-[#A3AED0]">
          {name}
        </p>
        <h1 className="text-start text-[#2B3674] text-lg font-bold">
          {amount}
        </h1>
      </div>
    </div>
  );
}
