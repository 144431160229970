import { lazy } from "react";
import { Navigate, Route } from "react-router-dom";

import { APP_ROUTE, HQ_APP_ROUTE } from "src/helpers/Constant";
import { Dashboard } from "src/screens";

import { HQProtectedComp } from "src/screens/protected/HqProtected";
import ManageHQBranch from "../hq-pages/Manage-branch";
import SingleBranch from "../hq-pages/Manage-branch/SingleBranch";
import Notification from "../hq-pages/Notification";
import HqSetting from "../hq-pages/Settings";
import WithDrawal from "../hq-pages/Withdraw.tsx";
import { Depot } from "src/components/Icons";
import DepotList from "../hq-pages/Depot/DepotList";
import SingleDepot from "../hq-pages/Depot/SingleDepot";
import RequestList from "../hq-pages/Depot/RequestList";
import InvoiceList from "../hq-pages/Depot/InvoiceList";
import OrderProductList from "../hq-pages/Depot/OrderProductList";
import SingleAttendentsProfile from "src/screens/dashboard/pages/Manage-branch/SingleAttendentsProfile";
import Wallet from "../hq-pages/Wallet/Wallet";
import DepotMarketList from "../hq-pages/Depot/DepotMarketList";
import SingleInvoice from "../hq-pages/Depot/SingleInvoice";
import SingleMarketPlaceDepot from "../hq-pages/Depot/components/SingleMarketPlaceDepot";

export const HQ_Route = (host: string) => {
  return [
    {
      path: APP_ROUTE.DASHBOARD,
      element: <Dashboard />,
    },
    {
      path: HQ_APP_ROUTE.BRANCH,
      element: <ManageHQBranch />,
    },
    {
      path: HQ_APP_ROUTE.SINGLE_BRANCH,
      element: <SingleBranch />,
    },

    {
      path: HQ_APP_ROUTE.SETTINGS,
      element: <HqSetting />,
    },
    {
      path: HQ_APP_ROUTE.DEPOT,
      element: <DepotList />,
    },
    {
      path: HQ_APP_ROUTE.DEPOT_MARKET,
      element: <DepotMarketList />,
    },
    {
      path: HQ_APP_ROUTE.SINGLE_DEPOT,
      element: <SingleDepot />,
    },
    {
      path: HQ_APP_ROUTE.SINGLE_ATTENDANTS,
      element: <SingleAttendentsProfile />,
    },
    {
      path: HQ_APP_ROUTE.REQUEST_LIST,
      element: <RequestList />,
    },
    {
      path: HQ_APP_ROUTE.INVOICE_LIST,
      element: <InvoiceList />,
    },
    {
      path: HQ_APP_ROUTE.PRODUCT_LIST,
      element: <OrderProductList />,
    },
    {
      path: HQ_APP_ROUTE.WALLET,
      element: <Wallet />,
    },
    {
      path: HQ_APP_ROUTE.WIDTHDRAWAL,
      element: <WithDrawal />,
    },

    {
      path: HQ_APP_ROUTE.NOTIFICATION,
      element: <Notification />,
    },
    {
      path: HQ_APP_ROUTE.SINGLE_INVOICE,
      element: <SingleInvoice />,
    },
    {
      path: HQ_APP_ROUTE.SUPPORT,
      element: <Wallet />,
    },
    {
      path: HQ_APP_ROUTE.SINGLE_MARKETPLACE_DEPOT,
      element: <SingleMarketPlaceDepot />,
    },
  ].map((route, i) => (
    <Route
      path={route.path}
      index
      element={<HQProtectedComp>{route.element}</HQProtectedComp>}
    />
  ));
};
