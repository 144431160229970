import React from "react";

export default function NotificationIndicator({ status }: any) {
	status =
		status === "pending"
			? "bg-yellow-600"
			: status === "approved"
			? "bg-green-600"
			: "bg-red-600";
	return (
		<div className=" ml-auto">
			<span className={`inline-block h-6 w-6  rounded-full ${status}`}></span>
		</div>
	);
}
