import { apiSlice } from "src/api/apiSlice";
import { HQ_API_ENPOINTS, RTKTAG } from "src/helpers/Constant";
//pyro-staging-api.up.railway.app/api/v1/station/branch/withdraw-request?populate=stationBranch,stationHQ&status=pending&stationBranch=646a59a6d55ce81a82c07548&stationHq={{STATION_HQ}}

export const hqTransactionSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllHQTransactions: builder.query({
      query: (params: any) =>
        ({
          url: HQ_API_ENPOINTS.TRANSACTIONS,
          params,
        } as any),
    }),
    getAllBraches: builder.query({
      query: () =>
        ({
          url: HQ_API_ENPOINTS.BRANCHES,
        } as any),
    }),
    getAllWallet: builder.query({
      query: (params) =>
        ({
          url: `${HQ_API_ENPOINTS.WALLET}/${params.stationBranch}`,
        } as any),
    }),
    withDrawFromStation: builder.mutation({
      query: ({ from, ...body }) =>
        ({
          url: `${HQ_API_ENPOINTS.HQ_WITHDRAW}?from=${from}&${
            body.stationBranch ? "stationBranch=" + body.stationBranch : ""
          }`,
          method: "Post",
          body,
        } as any),
      invalidatesTags: [
        { type: RTKTAG.HQ_BRANCH },
        { type: RTKTAG.MANAGER_BRANCH },
      ] as any,
    }),
    validateAccountNumberForWithdrawal: builder.mutation({
      query: (body) =>
        ({
          url: `payment/validate-account`,
          method: "POST",
          body,
        } as any),
    }),
    hqWallet: builder.query({
      query: (params) =>
        ({
          url: `${HQ_API_ENPOINTS.HQ_WALLET}`,
        } as any),
      providesTags: [{ type: RTKTAG.HQ_WALLET }] as any,
    }),
    branchWallet: builder.query({
      query: (id: string) =>
        ({
          url: `${HQ_API_ENPOINTS.WALLET}/${id}`,
        } as any),
      providesTags: [{ type: RTKTAG.BRANCH_WALLET }] as any,
    }),
    getAllWidthdrawRequest: builder.query({
      query: (params) =>
        ({
          url: HQ_API_ENPOINTS.WALLET_REQUEST,
          params,
        } as any),
      providesTags: [{ type: RTKTAG.WITHDRAWAL_REQUEST }] as any[],
    }),
    approveWithdrawalRequest: builder.mutation({
      query: (body: any) =>
        ({
          url: `${HQ_API_ENPOINTS.WALLET_REQUEST}/${body.id}`,
          method: "PATCH",
          body: body.body,
        } as any),
      invalidatesTags: [{ type: RTKTAG.WITHDRAWAL_REQUEST }] as any,
    }),
    addProducts: builder.mutation({
      query: ({ id, ...body }) =>
        ({
          url: `${HQ_API_ENPOINTS.SINGLE_BRANCH}/${id}/products`,
          method: "Post",
          body,
        } as any),
    }),
    updateTransactionPin: builder.mutation({
      query: (body) =>
        ({
          url: `${HQ_API_ENPOINTS.UPDATE_TRANSACTION_PIN}`,
          method: "PATCH",
          body,
        } as any),
    }),
    verifyTransactionPin: builder.mutation({
      query: (body) =>
        ({
          url: `${HQ_API_ENPOINTS.VERIFY_TRANSACTION_PIN}`,
          method: "POST",
          body,
        } as any),
    }),
    addAmenities: builder.mutation({
      query: ({ id, ...body }: any) =>
        ({
          url: `${HQ_API_ENPOINTS.SINGLE_BRANCH}/${id}/amenities`,
          method: "Post",
          body,
        } as any),
    }),
    bankList: builder.query({
      query: (params) =>
        ({
          url: HQ_API_ENPOINTS.BANK_LIST,
          method: "Get",
          params,
        } as any),
    }),
    bank: builder.query({
      query: (uuid) =>
        ({
          url: `${HQ_API_ENPOINTS.BANK}/${uuid}`,
          method: "Get",
        } as any),
    }),
  }),
});

export const {
  useGetAllBrachesQuery,
  useGetAllHQTransactionsQuery,
  useGetAllWidthdrawRequestQuery,
  useAddProductsMutation,
  useAddAmenitiesMutation,
  useGetAllWalletQuery,
  useApproveWithdrawalRequestMutation,
  useHqWalletQuery,
  useBankListQuery,
  useBankQuery,
  useWithDrawFromStationMutation,
  useVerifyTransactionPinMutation,
  useBranchWalletQuery,
  useUpdateTransactionPinMutation,
  useValidateAccountNumberForWithdrawalMutation,
} = hqTransactionSlice;
