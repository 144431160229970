import React, { ReactNode } from "react";
import Image from "./Image";
import Logo from "src/assets/img/Logo.jpeg";

export default function AuthWrapper({
  children,
  name,
}: {
  children: ReactNode;
  name: string;
}) {
  return (
    <section className="w-full h-screen ">
      <article className="min-h-full h-auto overflow-y-auto py-24 w-full flex flex-col justify-center backgroundImage">
        <div className=" mx-auto py-10 flex  max-h-fit rounded-lg flex-col items-center w-[90%] sm:max-w-[470px] bg-white">
          <div className="">
            <div className="mb-1">
              <Image image={Logo} width={220} styles="mx-auto -mb-10 -mt-10" />
            </div>
            <h1 className="text-[20px] font-bold px-20  leading-[30px] text-black">
              {name}
            </h1>
          </div>
          {children}
        </div>
      </article>
    </section>
  );
}
