import Image from "src/components/Image";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { useMemo, useState } from "react";
import styled from "@emotion/styled";
import { Badge, Collapse } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";
import { ReactNode } from "react";
import { useAppDispatch } from "src/hooks/reduxhooks";
import { logOut } from "src/features/auth/authSlice";
import {
  ArrowBack,
  NotificationAddRounded,
  NotificationsActive,
} from "@mui/icons-material";
import { IoNotifications } from "react-icons/io5";
import { useFetchAllHQNotificationQuery } from "src/hq-admin/hq-api/notificationApiSlice";
import { forEnums, handleDateFormat } from "src/helpers/helperFunction";
import { useDebounce } from "src/hooks/useDebounce";
import { useGetHQDetailsQuery } from "src/hq-admin/hq-api/hqAuthSlice";
import { apiSlice } from "src/api/apiSlice";

const DashboardHeader = (props: { header: any }) => {
  // const { user } = useAuth();
  const hqDetailsResult = useGetHQDetailsQuery({});
  const user = useMemo(() => hqDetailsResult?.data?.data, [hqDetailsResult]);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [notificationExpanded, setNotificationExpanded] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [filteredValue, setFilteredValue] = useState<string>("");
  const { debouncedValue } = useDebounce(filteredValue, 700);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleNotificationExpandClick = (e: any) => {
    e.stopPropagation();
    setNotificationExpanded(!notificationExpanded);
  };

  interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }
  const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
  }));
  const ExpandNotificationMore = styled((props: any) => {
    const { show, ...other } = props;
    return <IconButton {...other} />;
  });

  const notificationResult = useFetchAllHQNotificationQuery({
    // ...(debouncedValue && { search: debouncedValue }),
    // for: "stationBranch",
    stationBranch: user?.stationHQ?.admin,
  });

  const handledAPIResponse = useMemo(() => {
    const hqProfile = notificationResult?.currentData?.data || [];

    return hqProfile?.data
      ?.filter((item: { read: any }) => item?.read)
      .reduce(
        (
          acc: { [index: string]: string }[],
          cur: {
            [index: string]: string;
          }
        ) => [
          ...acc,
          {
            id: cur?.id,
            title: cur?.title,
            message: cur?.message,
            for: forEnums[cur?.for],
            createdAt: handleDateFormat(cur?.createdAt),
          },
        ],
        []
      );
  }, [notificationResult]);

  function onLogOut() {
    dispatch(apiSlice.util.resetApiState());
    dispatch(logOut());
    navigate("/login");
  }
  return (
    <div className=" flex justify-between items-center mb-2 fixed w-[82vw] h-24 top-0 bg-[#eff4f6] pr-10">
      <div className="  text-start  text-white mx-2">
        {location?.state?.toLowerCase() !== "dashboard" ? (
          <ArrowBack
            sx={{ height: 30, width: 30, color: "#002E66" }}
            onClick={() => navigate(-1)}
          />
        ) : null}
      </div>
      <h1 className="text-start text-[#002E66]  text-base md:text-xl font-[700] leading-[45px] w-full">
        {typeof props.header !== "object"
          ? props.header
          : props.header?.name ||
            location?.state?.firstName + " " + location?.state?.lastName}
      </h1>
      <div className="w-full flex items-center justify-end  gap-4">
        <div
          className="flex items-center justify-between  relative cursor-pointer"
          onClick={handleExpandClick}
        >
          <p className="font-normal text-sm lg:text-[18px] text-[#393939] mr-4 ">
            {user?.stationHQ?.name}
          </p>

          <div className="h-[50px] w-[65px] flex justify-center items-center rounded-full">
            <Image
              width="100%"
              height="100%"
              image={user?.stationHQ?.logo?.url}
              styles="rounded-full h-full w-full object-contain"
            />
          </div>

          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
          {/* <div className=" py-4 px-5">
            <IconButton onClick={handleNotificationExpandClick}>
              <Badge
                color="warning"
                badgeContent={handledAPIResponse?.length}
                max={6}
              >
                <IoNotifications size={30} color="" />
              </Badge>
            </IconButton>
          </div> */}
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <div
              className="h-screen w-screen fixed top-0 left-0 mt-6"
              onClick={handleExpandClick}
            >
              <div className="h-26 bg-white shadow-lg absolute right-0 top-14 w-44 text-[#393939]">
                <div className="text-start w-full py-2 transition-all hover:bg-[#D9D9D9] hover:scale-[1.1] p-4">
                  <Link to="/settings" state="Settings">
                    View Profile
                  </Link>
                </div>
                <button
                  onClick={() => onLogOut()}
                  className="text-start px-4 py-2 block w-full transition-all hover:bg-[#D9D9D9] hover:scale-[1.1]"
                >
                  <p>Log out</p>
                </button>
                {/* <Button /> */}
              </div>
            </div>
          </Collapse>

          <Collapse in={notificationExpanded} timeout="auto" unmountOnExit>
            <div
              className="h-screen w-screen fixed top-0 left-0 "
              onClick={handleNotificationExpandClick}
            >
              <div className="h-full p-3 white shadow-lg absolute right-6 w-96 overflow-y-auto  top-14 text-[#393939] bg-white ">
                {handledAPIResponse?.length !== 0 ? (
                  <>
                    {handledAPIResponse?.map((item: any, i: any) => {
                      return (
                        <div
                          key={i}
                          className="h-fit py-3  w-full px-2 space-y-2 text-left transition-shadow hover:shadow-lg"
                        >
                          <h3 className="font-bold">{item?.title}</h3>
                          <p className="text-sm">{item?.message}</p>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="flex mt-10 items-center justify-center">
                    <h5>No notification</h5>
                  </div>
                )}

                {/* <Button /> */}
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

interface propstype {
  [index: string]: any;
}
export const DropDownComponent = (props: propstype) => {
  interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }
  const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
  }));

  return (
    <div className="flex items-center justify-center w-fit">
      {props.text ? (
        <p className="text-[12px] text-black">{props.text}</p>
      ) : null}
      <ExpandMore
        expand={props.expanded}
        onClick={props.handleExpandClick}
        aria-expanded={props.expanded}
        aria-label="show more"
      >
        <ExpandMoreIcon />
      </ExpandMore>
      <Collapse in={props.expanded} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
    </div>
  );
};

const Main = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const headerName = location.state || "Dashboard";

  return (
    <main className="basis-[82%] py-4 overflow-x-hidden h-full">
      <DashboardHeader header={headerName} />
      <section className="w-[100%] h-fit overflow-x-hidden px-3 mt-24 ">
        {children}
      </section>
    </main>
  );
};

export default Main;
